import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGrid from "../../../../components/admin/AdminDataGrid";
import { getDivisionColumns } from "../../../../constants/columns/admin/division/getDivisionColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteDivision } from "../../../../services/admin/division/deleteDivision";
import { getDivision } from "../../../../services/admin/division/getDivision";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const DivisionList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchDivision = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getDivision();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      setDataGridState((prevState) => ({
        ...prevState,
        rows: res.payload,
      }));
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };
  const handleDeleteDivision = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteDivision(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchDivision();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateDivision = (id) => {
    navigate(`/admin/division/${id}`);
  };

  useEffect(() => {
    fetchDivision();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGrid
      columns={getDivisionColumns(
        dialogContext,
        handleDeleteDivision,
        handleUpdateDivision
      )}
      dataGridState={dataGridState}
    />
  );
};

export default DivisionList;
