import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  Menu,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DrawerItems from "../../components/admin/DrawerItems";
import MyBreadcrumbs from "../../components/admin/MyBreadcrumbs";
import UserMenuItems from "../../components/admin/UserMenuItems";
import Loader from "../../components/Loader/Loader";
import { adminDrawerItems } from "../../constants/adminDrawerItems";
import LoaderContext from "../../contexts/LoaderContextProvider";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    useMediaQuery(theme.breakpoints.up("md")) && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: useMediaQuery(theme.breakpoints.up("md"))
      ? `-${drawerWidth}px`
      : 0,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AdminLayout = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const loaderContext = useContext(LoaderContext);
  const location = useLocation();
  const theme = useTheme();
  const mqMd = useMediaQuery(theme.breakpoints.up("md"));

  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenDrawer = () => {
    setIsOpenDrawer(true);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const initDrawerTitle = () => {
    const split = location.pathname.split("/");
    const tempInteger = parseInt(split[split.length - 1]);
    let url = "";

    if (!isNaN(tempInteger)) {
      split.pop();
      url = split.join("/");
    }

    adminDrawerItems.forEach((drawerItem) => {
      if (!drawerItem.children) {
        if (location.pathname === drawerItem.path || url === drawerItem.path) {
          setDrawerTitle(drawerItem.text);
        }
      } else {
        drawerItem.children.forEach((drawerChild) => {
          if (
            location.pathname === drawerChild.path ||
            url === drawerChild.path
          ) {
            setDrawerTitle(drawerChild.text);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (!mqMd) {
      handleCloseDrawer();
    } else {
      handleOpenDrawer();
    }

    return () => {};
    // eslint-disable-next-line
  }, [mqMd]);

  useEffect(() => {
    initDrawerTitle();
    if (!mqMd) {
      handleCloseDrawer();
    }

    return () => {};
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline enableColorScheme />
      <AppBar enableColorOnDark open={isOpenDrawer} position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleOpenDrawer}
            sx={{ mr: 2, ...(mqMd && isOpenDrawer && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="div" noWrap sx={{ flexGrow: 1 }} variant="h6">
            {drawerTitle}
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="admin-avatar" src="" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorElUser}
              anchorOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{ mt: "45px" }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
            >
              <UserMenuItems />
            </Menu>
          </Box>
        </Toolbar>
        {isLoadingLinearProgress && <LinearProgress />}
      </AppBar>
      <Drawer
        anchor="left"
        onClose={handleCloseDrawer}
        open={isOpenDrawer}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
          flexShrink: 0,
          width: drawerWidth,
        }}
        variant={mqMd ? "persistent" : "temporary"}
      >
        <DrawerHeader sx={{ bgcolor: "background.paper" }}>
          <IconButton onClick={handleCloseDrawer}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <DrawerItems />
      </Drawer>
      <Main open={isOpenDrawer} sx={{ minWidth: "0px" }}>
        <DrawerHeader />
        <MyBreadcrumbs />
        {loaderContext.isLoading && <Loader />}
        <div
          style={{
            display: loaderContext.isLoading ? "none" : "block",
          }}
        >
          <Outlet
            context={[isLoadingLinearProgress, setIsLoadingLinearProgress]}
          />
        </div>
      </Main>
    </Box>
  );
};

export default AdminLayout;
