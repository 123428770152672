import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { manageUserSchema } from "../../../../schemas/manageUserSchema";
import { createUser } from "../../../../services/admin/manageUser/createUser";
import { getUserRole } from "../../../../services/admin/manageUserRole/getUserRole";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ManageUserCreate = () => {
  const {
    control,
    formState: { isSubmitted },
    handleSubmit,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      confirm_password: "",
      password: "",
      username: "",
      user_role: null,
    },
    resolver: yupResolver(manageUserSchema),
  });
  const [autocompleteUserRoleOptions, setAutocompleteUserRoleOptions] =
    useState([]);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  const [isLoadingAutocompleteUserRole, setIsLoadingAutocompleteUserRole] =
    useState(false);
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompleteUserRole, setIsOpenAutocompleteUserRole] =
    useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateUser = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.user_role_id = data.user_role.id;

      const res = await createUser(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete user role
  useEffect(() => {
    setAutocompleteUserRoleOptions([]);

    if (!isOpenAutocompleteUserRole) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUserRole(true);

        const res = await getUserRole();

        if (res.status === 200) {
          setAutocompleteUserRoleOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUserRole(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUserRole]);

  return (
    <form onSubmit={handleSubmit(handleCreateUser)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="username"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Username"
                onChange={onChange}
                value={value || ""}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="user_role"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUserRole}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUserRole(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUserRole(true);
                }}
                open={isOpenAutocompleteUserRole}
                options={autocompleteUserRoleOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUserRole ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Peran"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="password"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl fullWidth>
                <InputLabel error={!!error}>Password</InputLabel>
                <Input
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setIsShowPassword(!isShowPassword)}
                        edge="end"
                      >
                        {isShowPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={!!error}
                  onChange={(e) => {
                    onChange(e);
                    if (isSubmitted) {
                      trigger(["confirm_password"]);
                    }
                  }}
                  ref={ref}
                  type={isShowPassword ? "text" : "password"}
                  value={value}
                />
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="confirm_password"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <FormControl fullWidth>
                <InputLabel error={!!error}>Confirm Password</InputLabel>
                <Input
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setIsShowConfirmPassword(!isShowConfirmPassword)
                        }
                        edge="end"
                      >
                        {isShowConfirmPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={!!error}
                  onChange={onChange}
                  ref={ref}
                  type={isShowConfirmPassword ? "text" : "password"}
                  value={value}
                />
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default ManageUserCreate;
