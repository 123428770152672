import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { employeeSchema } from "../../../../schemas/employeeSchema";
import { createEmployee } from "../../../../services/employee/createEmployee";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const autocompleteBloodTypeOptions = ["A", "AB", "B", "O"];
const autocompleteGenderOptions = [
  "Pria",
  "Wanita",
  "Sebaiknya Tidak Dibicarakann",
];
const autocompleteMaritalStatusOptions = [
  "Belum Kawin",
  "Kawin",
  "Cerai Hidup",
  "Cerai Mati",
];
const autocompleteReligionOptions = [
  "Buddha",
  "Islam",
  "Hindu",
  "Katolik",
  "Khonghucu",
  "Kristen",
];

const EmployeeCreate = () => {
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: "",
      blood_type: "",
      citizenship: "",
      date_of_birth: "",
      email: "",
      gender: "",
      identity_number: "",
      marital_status: "",
      name: "",
      phone_number: "",
      place_of_birth: "",
      religion: "",
      rt_rw: "",
      subdistrict: "", // kecamatan
      ward: "", // keluarahan
    },
    resolver: yupResolver(employeeSchema),
  });
  const [isLoadingAutocompleteBloodType, setIsLoadingAutocompleteBloodType] =
    useState(false);
  const [isLoadingAutocompleteGender, setIsLoadingAutocompleteGender] =
    useState(false);
  const [
    isLoadingAutocompleteMaritalStatus,
    setIsLoadingAutocompleteMaritalStatus,
  ] = useState(false);
  const [isLoadingAutocompleteReligion, setIsLoadingAutocompleteReligion] =
    useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompleteBloodType, setIsOpenAutocompleteBloodType] =
    useState(false);
  const [isOpenAutocompleteGender, setIsOpenAutocompleteGender] =
    useState(false);
  const [isOpenAutocompleteMaritalStatus, setIsOpenAutocompleteMaritalStatus] =
    useState(false);
  const [isOpenAutocompleteReligion, setIsOpenAutocompleteReligion] =
    useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateEmployee = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createEmployee(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateEmployee)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="identity_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="NIK"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="place_of_birth"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Tempat Lahir"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="date_of_birth"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Lahir"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="gender"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                loading={isLoadingAutocompleteGender}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteGender(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteGender(true);
                }}
                open={isOpenAutocompleteGender}
                options={autocompleteGenderOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingAutocompleteGender ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Jenis Kelamin"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="blood_type"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                loading={isLoadingAutocompleteBloodType}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteBloodType(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteBloodType(true);
                }}
                open={isOpenAutocompleteBloodType}
                options={autocompleteBloodTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingAutocompleteBloodType ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Golongan Darah"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Email"
                onChange={onChange}
                type="email"
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="phone_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nomor Telepon"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Alamat"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="rt_rw"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="RT/RW"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="ward"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kelurahan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="subdistrict"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kecamatan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="religion"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                loading={isLoadingAutocompleteReligion}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteReligion(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteReligion(true);
                }}
                open={isOpenAutocompleteReligion}
                options={autocompleteReligionOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingAutocompleteReligion ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Agama"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="marital_status"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                loading={isLoadingAutocompleteMaritalStatus}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteMaritalStatus(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteMaritalStatus(true);
                }}
                open={isOpenAutocompleteMaritalStatus}
                options={autocompleteMaritalStatusOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingAutocompleteMaritalStatus ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Status Perkawinan"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="citizenship"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kewarganegaraan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default EmployeeCreate;
