import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Grid, Paper, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { bankCashCategorySchema } from "../../../../schemas/bankCashCategorySchema";
import { getBankCashCategoryById } from "../../../../services/admin/bankCashCategory/getBankCashCategoryById";
import { updateBankCashCategory } from "../../../../services/admin/bankCashCategory/updateBankCashCategory";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const BankCashCategoryDetail = () => {
  const { id } = useParams();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
      code: "",
    },
    resolver: yupResolver(bankCashCategorySchema),
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchBankCashCategoryById = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getBankCashCategoryById(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setValue("name", res.payload.name);
        setValue("code", res.payload.code);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateBankCashCategory = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.id = parseInt(id);

      const res = await updateBankCashCategory(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchBankCashCategoryById();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchBankCashCategoryById();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateBankCashCategory)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="name"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nama Kategori Kas Bank"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="code"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Kode Kategori Kas Bank"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonUpdate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default BankCashCategoryDetail;
