import * as yup from "yup";

export const manageUserUpdateSchema = yup.object().shape({
  username: yup
    .string()
    .max(32, "The length of your Username must be 32 characters or fewer.")
    .min(2, "The length of your Username must be at least 2 characters.")
    .required("Please enter your Username."),
  user_role: yup.object().required("Please enter your Role."),
});
