import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const CalculateBudgetDialog = forwardRef(function (
  { append, getValues, control, fields, remove, setValue, title },
  ref
) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState("0");

  const calculateBudget = () => {
    const packages = getValues().details;

    let totalBudget = 0;

    for (let i = 0; i < packages.length; i++) {
      totalBudget += packages[i].amount;
    }

    setValue("budget_total", totalBudget);
  };

  const handleAddCalculateBudgetDetails = () => {
    append({
      amount: 0,
      description: "",
      title: "",
    });

    calculateBudget();
  };

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleRemoveCalculateBudgetDetail = (index) => {
    remove(index);
    index -= 1;
    if (index < 0) {
      index = 0;
    }
    setTabValue(index.toString());

    calculateBudget();
  };

  useImperativeHandle(
    ref,
    () => {
      return { handleCloseDialog, handleOpenDialog };
    },
    []
  );

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth="md"
      onClose={handleCloseDialog}
      open={isOpenDialog}
    >
      <DialogTitle>Detail {title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Paper sx={{ borderRadius: 0 }}>
          {fields.length <= 0 ? (
            <Typography sx={{ p: 3, textAlign: "center" }}>No Data</Typography>
          ) : (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  allowScrollButtonsMobile
                  onChange={handleChangeTabValue}
                  scrollButtons
                  variant="scrollable"
                >
                  {fields.map((item, index) => {
                    return (
                      <Tab
                        key={item.id}
                        label={`${title} ${index + 1}`}
                        value={index.toString()}
                      />
                    );
                  })}
                </TabList>
              </Box>
              {fields.map((item, index) => {
                return (
                  <TabPanel key={item.id} value={index.toString()}>
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`details.${index}.amount`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <NumericFormat
                              allowNegative={false}
                              customInput={TextField}
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputProps={{ inputMode: "numeric" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Rp
                                  </InputAdornment>
                                ),
                              }}
                              inputRef={ref}
                              label="Jumlah"
                              onValueChange={(values) => {
                                onChange(values.floatValue);
                                calculateBudget();
                              }}
                              thousandSeparator=","
                              value={value}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`details.${index}.title`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputRef={ref}
                              label="Detail"
                              onChange={onChange}
                              value={value || ""}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`details.${index}.description`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputRef={ref}
                              label="Keterangan"
                              minRows={1}
                              multiline
                              onChange={onChange}
                              value={value || ""}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                );
              })}
            </TabContext>
          )}
        </Paper>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 0, sm: 2 }}
        >
          {fields.length > 0 && (
            <Button
              color="error"
              endIcon={<DeleteIcon />}
              onClick={() =>
                handleRemoveCalculateBudgetDetail(parseInt(tabValue))
              }
              variant="text"
            >
              Hapus {title} {parseInt(tabValue) + 1}
            </Button>
          )}
          <Button
            endIcon={<AddIcon />}
            onClick={handleAddCalculateBudgetDetails}
            variant="text"
          >
            Tambah {title}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default CalculateBudgetDialog;
