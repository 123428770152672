import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";
import { globalTheme } from "./globalTheme";

export const lightTheme = createTheme({
  ...globalTheme,
  palette: {
    action: {
      focus: "rgba(0, 0, 0, 0.15)",
      selected: "rgba(0, 0, 0, 0.2)",
      hover: "rgba(0, 0, 0, 0.1)",
    },
    mode: "light",
    primary: {
      main: orange[300],
      light: orange[100],
      dark: orange[500],
    },
  },
});
