import { Alert, Slide, Snackbar } from "@mui/material";
import { createContext, useState } from "react";

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const SnackbarContext = createContext();

const SnackbarContextProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    autoHideDuration: 3000,
    isOpen: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleOpenSnackbar = (message, status) => {
    if (message) {
      setSnackbarState((prevState) => ({
        ...prevState,
        isOpen: true,
        message: message,
        severity: status >= 200 && status <= 208 ? "success" : "error",
      }));
    }
  };

  return (
    <SnackbarContext.Provider
      value={{ handleCloseSnackbar, handleOpenSnackbar }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={snackbarState.autoHideDuration}
        onClose={handleCloseSnackbar}
        open={snackbarState.isOpen}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarState.severity}
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export { SnackbarContextProvider };
export default SnackbarContext;
