import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Forbidden from "../components/Forbidden";
import NotFound from "../components/NotFound";
import { modules } from "../constants/modules";
import AdminLayout from "../layouts/AdminLayout/AdminLayout";
import { AdminMiddleware } from "../middlewares/AdminMiddleware";
import { LoginMiddleware } from "../middlewares/LoginMiddleware";
import RoleMiddleware from "../middlewares/RoleMiddleware";
import Login from "../pages/Login";
import BankCash from "../pages/admin/BankCash";
import BankCashDetail from "../pages/admin/BankCash/BankCashDetail/";
import BankCashCategory from "../pages/admin/BankCashCategory";
import BankCashCategoryDetail from "../pages/admin/BankCashCategory/BankCashCategoryDetail";
import CalculateBudget from "../pages/admin/CalculateBudget";
import CalculateHpp from "../pages/admin/CalculateHpp";
import ChartOfAccount from "../pages/admin/ChartOfAccount";
import ChartOfAccountDetail from "../pages/admin/ChartOfAccount/ChartOfAccountDetail/ChartOfAccountDetail";
import CoaCategory from "../pages/admin/CoaCategory";
import CoaCategoryDetail from "../pages/admin/CoaCategory/CoaCategoryDetail";
import Company from "../pages/admin/Company";
import CompanyProfile from "../pages/admin/CompanyProfile";
import Currency from "../pages/admin/Currency";
import Dashboard from "../pages/admin/Dashboard";
import Department from "../pages/admin/Department";
import Division from "../pages/admin/Division/Division";
import DivisionDetail from "../pages/admin/Division/DivisionDetail";
import Employee from "../pages/admin/Employee";
import Fpd from "../pages/admin/Fpd";
import FpdApproval from "../pages/admin/Fpd/FpdApproval";
import FpdDetail from "../pages/admin/Fpd/FpdDetail/FpdDetail";
import FpdPaymentDetail from "../pages/admin/Fpd/FpdPaymentDetail";
import FpdPaymentList from "../pages/admin/Fpd/FpdPaymentList";
import ManageUser from "../pages/admin/ManageUser";
import ManageUserDetail from "../pages/admin/ManageUser/ManageUserDetail";
import ManageUserRole from "../pages/admin/ManageUserRole";
import ManageUserRoleDetail from "../pages/admin/ManageUserRole/ManageUserRoleDetail";
import PBF from "../pages/admin/PBF";
import Partner from "../pages/admin/Partner";
import PartnerDetail from "../pages/admin/Partner/PartnerDetail";
import Product from "../pages/admin/Product";
import ProductDetail from "../pages/admin/Product/ProductDetail";
import PurchaseOrder from "../pages/admin/PurchaseOrder";
import PurchaseOrderDetail from "../pages/admin/PurchaseOrder/PurchaseOrderDetail";
import Unit from "../pages/admin/Unit";
import UnitDetail from "../pages/admin/Unit/UnitDetail";
import Warehouse from "../pages/admin/Warehouse";
import { getLandingPage } from "../utils/getLandingPage";

const MyRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {};
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<LoginMiddleware />}>
        <Route element={<Login />} path="/" />
        <Route element={<Login />} path="/login" />
      </Route>
      <Route element={<AdminMiddleware />}>
        <Route path="/admin" element={<AdminLayout />}>
          <Route element={<Navigate to={getLandingPage()} />} path="" />
          <Route element={<RoleMiddleware />}>
            <Route element={<BankCash />} path={modules.BANK_CASH.key} />
            <Route
              element={<BankCashDetail />}
              path={`${modules.BANK_CASH.key}/:id`}
            />
            <Route
              element={<BankCashCategory />}
              path={modules.BANK_CASH_CATEGORY.key}
            />
            <Route
              element={<BankCashCategoryDetail />}
              path={`${modules.BANK_CASH_CATEGORY.key}/:id`}
            />
            <Route
              element={<CalculateBudget />}
              path={modules.CALCULATE_BUDGET.key}
            />
            <Route
              element={<CalculateHpp />}
              path={modules.CALCULATE_HPP.key}
            />
            <Route element={<CoaCategory />} path={modules.COA_CATEGORY.key} />
            <Route
              element={<CoaCategoryDetail />}
              path={`${modules.COA_CATEGORY.key}/:id`}
            />
            <Route
              element={<ChartOfAccount />}
              path={modules.CHART_OF_ACCOUNT.key}
            />
            <Route
              element={<ChartOfAccountDetail />}
              path={`${modules.CHART_OF_ACCOUNT.key}/:id`}
            />
            <Route element={<Company />} path={modules.COMPANY.key} />
            <Route element={<Currency />} path={modules.CURRENCY.key} />
            <Route
              element={<CompanyProfile />}
              path={modules.COMPANY_PROFILE.key}
            />
            <Route element={<Dashboard />} path={modules.DASHBOARD.key} />
            <Route element={<Department />} path={modules.DEPARTMENT.key} />
            <Route element={<Division />} path={modules.DIVISION.key} />
            <Route
              element={<DivisionDetail />}
              path={`${modules.DIVISION.key}/:id`}
            />
            <Route element={<Employee />} path={modules.EMPLOYEE.key} />
            <Route element={<Fpd />} path={modules.FPD.key} />
            <Route element={<FpdApproval />} path={modules.FPD_APPROVAL.key} />
            <Route element={<FpdDetail />} path={`${modules.FPD.key}/:id`} />
            <Route
              element={<FpdPaymentDetail />}
              path={`${modules.FPD_PAYMENT.key}/:id`}
            />
            <Route
              element={<FpdPaymentList />}
              path={modules.FPD_PAYMENT.key}
            />
            <Route element={<ManageUser />} path={modules.MANAGE_USER.key} />
            <Route
              element={<ManageUserDetail />}
              path={`${modules.MANAGE_USER.key}/:id`}
            />
            <Route
              element={<ManageUserRole />}
              path={modules.MANAGE_USER_ROLE.key}
            />
            <Route
              element={<ManageUserRoleDetail />}
              path={`${modules.MANAGE_USER_ROLE.key}/:id`}
            />
            <Route element={<Partner />} path={modules.PARTNER.key} />
            <Route
              element={<PartnerDetail />}
              path={`${modules.PARTNER.key}/:id`}
            />
            <Route element={<PBF />} path={modules.PBF.key} />
            <Route
              element={<PurchaseOrder />}
              path={modules.PURCHASE_ORDER.key}
            />
            <Route
              element={<PurchaseOrderDetail />}
              path={`${modules.PURCHASE_ORDER.key}/:id`}
            />
            <Route element={<Product />} path={modules.PRODUCT.key} />
            <Route
              element={<ProductDetail />}
              path={`${modules.PRODUCT.key}/:id`}
            />
            <Route element={<Unit />} path={modules.UNIT.key} />
            <Route element={<UnitDetail />} path={`${modules.UNIT.key}/:id`} />
            <Route element={<Warehouse />} path={modules.WAREHOUSE.key} />
            <Route element={<NotFound />} path="*" />
            <Route element={<Forbidden isShowButton={true} />} path="403" />
          </Route>
        </Route>
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MyRoutes;
