import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import AdminDataGridRow from "../../../../components/admin/AdminDataGridRow";
import { getFpdAccountColumns } from "../../../../constants/columns/admin/fpd/getFpdAccountColumns";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { fpdPaymentSchema } from "../../../../schemas/fpdPaymentSchema";
import { getBankCashCategory } from "../../../../services/admin/bankCashCategory/getBankCashCategory";
import { getChartOfAccount } from "../../../../services/admin/chartOfAccount/getChartOfAccount";
import { getFpdById } from "../../../../services/admin/fpd/getFpdById";
import { paymentFpd } from "../../../../services/admin/fpd/paymentFpd";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const FpdPaymentDetail = () => {
  const { id } = useParams();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      bank_cash_category: null,
      payment_date: null,
    },
    resolver: yupResolver(fpdPaymentSchema),
  });
  const [
    autocompleteBankCashCategoryOptions,
    setAutocompleteBankCashCategoryOptions,
  ] = useState([]);
  const [autocompleteCoaOptions, setAutocompleteCoaOptions] = useState([]);
  const [
    isLoadingAutocompleteBankCashCategory,
    setIsLoadingAutocompleteBankCashCategory,
  ] = useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [
    isOpenAutocompleteBankCashCategory,
    setIsOpenAutocompleteBankCashCategory,
  ] = useState(false);
  const adminDataGridRowRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchFpd = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getFpdById(id);

      if (res.status === 200) {
        setValue("bank_cash_category", res.payload.bank_cash_category);
        if (res.payload.payment_date) {
          setValue(
            "payment_date",
            parse(res.payload.payment_date, "yyyy-MM-dd", new Date())
          );
        }

        let tempFpdDetail = [];

        for (let i = 0; i < res.payload.fpd_detail.length; i++) {
          const element = res.payload.fpd_detail[i];

          tempFpdDetail.push({
            id: element.id,
            amount: element.amount,
            coa_id: element.coa_id,
            description: element.description,
            isNew: false,
          });
        }

        adminDataGridRowRef.current.setRows(tempFpdDetail);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handlePaymentFpd = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.id = parseInt(id);
      data.payment_date = format(data.payment_date, "yyyy-MM-dd HH:mm:ss");
      data.fpd_detail = adminDataGridRowRef.current.rows;

      const res = await paymentFpd(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchFpd();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchFpd();

    return () => {};
    // eslint-disable-next-line
  }, []);

  // autocomplete bank cash category
  useEffect(() => {
    setAutocompleteBankCashCategoryOptions([]);

    if (!isOpenAutocompleteBankCashCategory) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteBankCashCategory(true);

        const res = await getBankCashCategory();

        if (res.status === 200) {
          setAutocompleteBankCashCategoryOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteBankCashCategory(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteBankCashCategory]);

  // autocomplete coa
  useEffect(() => {
    setAutocompleteCoaOptions([]);

    (async () => {
      try {
        setIsLoadingLinearProgress(true);

        const res = await getChartOfAccount();

        if (res.status === 200) {
          setAutocompleteCoaOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingLinearProgress(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handlePaymentFpd)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="bank_cash_category"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteBankCashCategory}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteBankCashCategory(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteBankCashCategory(true);
                  }}
                  open={isOpenAutocompleteBankCashCategory}
                  options={autocompleteBankCashCategoryOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingAutocompleteBankCashCategory ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label="Kategori Kas Bank"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="payment_date"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Pelunasan FPD"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <div
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            >
              <AdminDataGridRow
                columns={getFpdAccountColumns}
                ref={adminDataGridRowRef}
                props={{ coa_code: autocompleteCoaOptions }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonUpdate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default FpdPaymentDetail;
