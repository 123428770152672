import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import { transactionTypeOptions } from "../../../transactionTypeOptions";

export const getBankCashColumns = (
  dialogContext,
  handleDeleteBankCash,
  handleExportBankCash,
  handleGetBankCashDetails,
  handleUpdateBankCash
) => [
  {
    field: "id",
    headerName: "ID",
    width: 90,
  },
  {
    field: "date",
    flex: 3,
    headerName: "Tanggal",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "voucher_number",
    headerName: "Nomor Voucher",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "invoice_number",
    headerName: "Nomor Invoice",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "journal_number",
    headerName: "Nomor Jurnal",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "bank_cash_category",
    headerName: "Kategori Kas Bank",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.bank_cash_category?.name ?? "";
    },
  },
  {
    field: "transaction_type",
    headerName: "Jenis Transaksi",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return (
        transactionTypeOptions.find((e) => e.id === row.transaction_type)
          ?.name ?? ""
      );
    },
  },
  {
    field: "division",
    headerName: "Divisi",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.division?.name ?? "";
    },
  },
  {
    field: "description",
    headerName: "Keterangan",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<InfoIcon color="action" />}
        label="Detail"
        onClick={() => {
          handleGetBankCashDetails(params.row.bank_cash_detail);
        }}
      />,
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateBankCash(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteBankCash(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<FileDownloadIcon color="warning" />}
        label="Export"
        onClick={() => {
          handleExportBankCash(params.row.id, params.row.voucher_number);
        }}
        showInMenu
      />,
    ],
  },
];
