import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import background from "../../assets/images/background.jpg";
import logo from "../../assets/images/logo.svg";
import SnackbarContext from "../../contexts/SnackbarContextProvider";
import { loginSchema } from "../../schemas/loginSchema";
import { login } from "../../services/auth/login";
import cookie from "../../utils/cookie";
import { generateSnackbarErrorMessage } from "../../utils/generateSnackbarErrorMessage";
import styles from "./Login.module.scss";

const Login = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      username: "",
    },
    resolver: yupResolver(loginSchema),
  });
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const onSubmitLogin = async (data) => {
    try {
      const res = await login(data);

      if (res.status === 200) {
        cookie.setCookie(
          "is_logged_in",
          true,
          `${process.env.REACT_APP_COOKIE_EXPIRES_IN}`
        );
        localStorage.setItem("user_id", res.payload.user.id);
        localStorage.setItem("user_username", res.payload.user.username);
        localStorage.setItem("user_role_id", res.payload.user.user_role_id);
        localStorage.setItem("user_role_name", res.payload.user.user_role_name);
        localStorage.setItem("user_role_json", res.payload.user.user_role_json);
        navigate("/admin");
      }

      snackbarContext.handleOpenSnackbar(res.message, res.status);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
    }
  };

  return (
    <div className={styles["login__container"]}>
      <form
        className={styles["login__box"]}
        onSubmit={handleSubmit(onSubmitLogin)}
      >
        <div className={styles["login__title"]}>
          <img src={logo} alt="" />
          <p>
            Log in to get in the moment updates on the things <br /> that
            interest you.
          </p>
        </div>
        <div className={styles["login__form"]}>
          <div
            className={`${styles["login__input"]} ${styles["login__input-username"]}`}
          >
            <label for="username">Email</label>
            <Controller
              control={control}
              name="username"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <>
                  <input
                    id="username"
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  />
                  {!!error?.message && (
                    <span className={styles["login__input--error"]}>
                      {error?.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>

          <div
            className={`${styles["login__input"]} ${styles["login__input-password"]}`}
          >
            <label for="password">Password</label>
            <Controller
              control={control}
              name="password"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <>
                  <input
                    id="password"
                    onChange={onChange}
                    ref={ref}
                    value={value}
                  />
                  {!!error?.message && (
                    <span className={styles["login__input--error"]}>
                      {error?.message}
                    </span>
                  )}
                </>
              )}
            />
          </div>
        </div>
        <div className={styles["login__action"]}>
          <button
            className={`${styles["login__button"]} ${styles["login__button--signin"]}`}
          >
            LOGIN
          </button>
          <button
            className={`${styles["login__button"]} ${styles["login__button--signup"]}`}
          >
            SIGN UP
          </button>
        </div>
      </form>
      <div className={styles["login__background"]}>
        <img src={background} alt="" />
      </div>
    </div>
  );
};

export default Login;
