import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGrid from "../../../../components/admin/AdminDataGrid";
import { getCoaCategoryColumns } from "../../../../constants/columns/admin/coaCategory/getCoaCategoryColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteCoaCategory } from "../../../../services/admin/coaCategory/deleteCoaCategory";
import { getCoaCategory } from "../../../../services/admin/coaCategory/getCoaCategory";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const CoaCategoryList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchCoaCategory = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getCoaCategory();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      setDataGridState((prevState) => ({
        ...prevState,
        rows: res.payload,
      }));
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteCoaCategory = async (id) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await deleteCoaCategory(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchCoaCategory();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateCoaCategory = (id) => {
    navigate(`/admin/coa_category/${id}`);
  };

  useEffect(() => {
    fetchCoaCategory();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGrid
      columns={getCoaCategoryColumns(
        dialogContext,
        handleDeleteCoaCategory,
        handleUpdateCoaCategory
      )}
      dataGridState={dataGridState}
    />
  );
};

export default CoaCategoryList;
