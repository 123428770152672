import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getWarehouseColumns = (
  dialogContext,
  handleDeleteWarehouse,
  handleUpdateWarehouse
) => [
  {
    field: "id",
    headerName: "ID",
    width: 200,
  },
  {
    field: "code",
    headerName: "Kode",
    width: 200,
  },
  {
    field: "address",
    headerName: "Alamat",
    width: 200,
  },
  {
    field: "name",
    headerName: "Kepala Gudang",
    width: 200,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateWarehouse(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteWarehouse(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
