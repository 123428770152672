import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import UnitCreate from "./UnitCreate";
import UnitList from "./UnitList";

const Unit = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label="Tambah Satuan" value="0" />
            <Tab label="Daftar Satuan" value="1" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.UNIT.key]: {
                module: modules.UNIT.key,
                features: [features.CREATE_DATA],
              },
            }}
          >
            <UnitCreate />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.UNIT.key]: {
                module: modules.UNIT.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <UnitList />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default Unit;
