export const getCalculateBudgetDetailColumns = () => [
  {
    field: "id",
    headerName: "ID",
    width: 150,
  },
  {
    field: "amount",
    headerName: "Jumlah",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "title",
    headerName: "Detail",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "description",
    headerName: "Keterangan",
    flex: 3,
    minWidth: 200,
  },
];
