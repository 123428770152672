import { getMethodToken } from "../../../utils/httpMethod/getMethodToken";

export const exportFpd = async (id, number) => {
  const res = await getMethodToken(`api/fpd/export_pdf/${id}`, {
    responseType: "blob",
  });

  const link = document.createElement("a");
  const tempFilename = number + ".pdf";
  link.setAttribute("download", tempFilename);
  link.href = URL.createObjectURL(new Blob([res]));
  document.body.appendChild(link);
  link.click();
  link.remove();
};
