import {
  faBoxArchive,
  faBuilding,
  faBuildingCircleCheck,
  faCalculator,
  faChartLine,
  faCity,
  faDatabase,
  faDollarSign,
  faFileCircleCheck,
  faFilePen,
  faFileShield,
  faGears,
  faHandHoldingDollar,
  faMoneyBillTransfer,
  faMoneyBills,
  faPeopleGroup,
  faUserGear,
  faUserGroup,
  faUserTie,
  faUsers,
  faUsersCog,
  faWarehouse,
  faWeightHanging,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategoryIcon from "@mui/icons-material/Category";
import { features } from "./features";
import { modules } from "./modules";

export const adminDrawerItems = [
  {
    icon: <FontAwesomeIcon icon={faChartLine} />,
    moduleFeatures: {
      [modules.DASHBOARD.key]: {
        module: modules.DASHBOARD.key,
        features: [features.READ_DATA],
      },
    },
    path: `/admin/${modules.DASHBOARD.key.toLowerCase()}`,
    text: modules.DASHBOARD.value,
  },
  {
    icon: <FontAwesomeIcon icon={faHandHoldingDollar} />,
    moduleFeatures: {
      [modules.FPD.key]: {
        module: modules.FPD.key,
        features: [features.READ_DATA],
      },
    },
    path: `/admin/${modules.FPD.key.toLowerCase()}`,
    text: modules.FPD.value,
  },
  {
    icon: <FontAwesomeIcon icon={faFileCircleCheck} />,
    moduleFeatures: {
      [modules.FPD_APPROVAL.key]: {
        module: modules.FPD_APPROVAL.key,
        features: [features.READ_DATA],
      },
    },
    path: `/admin/${modules.FPD_APPROVAL.key.toLowerCase()}`,
    text: modules.FPD_APPROVAL.value,
  },
  {
    icon: <FontAwesomeIcon icon={faMoneyBillTransfer} />,
    moduleFeatures: {
      [modules.BANK_CASH.key]: {
        module: modules.BANK_CASH.key,
        features: [features.READ_DATA],
      },
    },
    path: `/admin/${modules.BANK_CASH.key.toLowerCase()}`,
    text: modules.BANK_CASH.value,
  },
  {
    icon: <FontAwesomeIcon icon={faFilePen} />,
    moduleFeatures: {
      [modules.FPD_PAYMENT.key]: {
        module: modules.FPD_PAYMENT.key,
        features: [features.READ_DATA],
      },
    },
    path: `/admin/${modules.FPD_PAYMENT.key.toLowerCase()}`,
    text: modules.FPD_PAYMENT.value,
  },
  {
    icon: <FontAwesomeIcon icon={faDatabase} />,
    isOpen: true,
    moduleFeatures: {
      [modules.BANK_CASH_CATEGORY.key]: {
        module: modules.BANK_CASH_CATEGORY.key,
        features: [features.READ_DATA],
      },
      [modules.COA_CATEGORY.key]: {
        module: modules.COA_CATEGORY.key,
        features: [features.READ_DATA],
      },
      [modules.CHART_OF_ACCOUNT.key]: {
        module: modules.CHART_OF_ACCOUNT.key,
        features: [features.READ_DATA],
      },
      [modules.COMPANY.key]: {
        module: modules.COMPANY.key,
        features: [features.READ_DATA],
      },
      [modules.DEPARTMENT.key]: {
        module: modules.DEPARTMENT.key,
        features: [features.READ_DATA],
      },
      [modules.DIVISION.key]: {
        module: modules.DIVISION.key,
        features: [features.READ_DATA],
      },
      [modules.CURRENCY.key]: {
        module: modules.CURRENCY.key,
        features: [features.READ_DATA],
      },
      [modules.EMPLOYEE.key]: {
        module: modules.EMPLOYEE.key,
        features: [features.READ_DATA],
      },
      [modules.PARTNER.key]: {
        module: modules.PARTNER.key,
        features: [features.READ_DATA],
      },
      [modules.PRODUCT.key]: {
        module: modules.PRODUCT.key,
        features: [features.READ_DATA],
      },
      [modules.UNIT.key]: {
        module: modules.UNIT.key,
        features: [features.READ_DATA],
      },
      [modules.WAREHOUSE.key]: {
        module: modules.WAREHOUSE.key,
        features: [features.READ_DATA],
      },
    },
    text: "Master",
    children: [
      {
        icon: <CategoryIcon />,
        moduleFeatures: {
          [modules.COA_CATEGORY.key]: {
            module: modules.COA_CATEGORY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.COA_CATEGORY.key.toLowerCase()}`,
        text: modules.COA_CATEGORY.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUsers} />,
        moduleFeatures: {
          [modules.CHART_OF_ACCOUNT.key]: {
            module: modules.CHART_OF_ACCOUNT.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.CHART_OF_ACCOUNT.key.toLowerCase()}`,
        text: modules.CHART_OF_ACCOUNT.value,
      },
      {
        icon: <CategoryIcon />,
        moduleFeatures: {
          [modules.BANK_CASH_CATEGORY.key]: {
            module: modules.BANK_CASH_CATEGORY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.BANK_CASH_CATEGORY.key.toLowerCase()}`,
        text: modules.BANK_CASH_CATEGORY.value,
      },
      {
        icon: <FontAwesomeIcon icon={faBuilding} />,
        moduleFeatures: {
          [modules.COMPANY.key]: {
            module: modules.COMPANY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.COMPANY.key.toLowerCase()}`,
        text: modules.COMPANY.value,
      },
      {
        icon: <FontAwesomeIcon icon={faCity} />,
        moduleFeatures: {
          [modules.DEPARTMENT.key]: {
            module: modules.DEPARTMENT.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.DEPARTMENT.key.toLowerCase()}`,
        text: modules.DEPARTMENT.value,
      },
      {
        icon: <FontAwesomeIcon icon={faPeopleGroup} />,
        moduleFeatures: {
          [modules.DIVISION.key]: {
            module: modules.DIVISION.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.DIVISION.key.toLowerCase()}`,
        text: modules.DIVISION.value,
      },
      {
        icon: <FontAwesomeIcon icon={faDollarSign} />,
        moduleFeatures: {
          [modules.CURRENCY.key]: {
            module: modules.CURRENCY.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.CURRENCY.key.toLowerCase()}`,
        text: modules.CURRENCY.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUserTie} />,
        moduleFeatures: {
          [modules.EMPLOYEE.key]: {
            module: modules.EMPLOYEE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.EMPLOYEE.key.toLowerCase()}`,
        text: modules.EMPLOYEE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUserGroup} />,
        moduleFeatures: {
          [modules.PARTNER.key]: {
            module: modules.PARTNER.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.PARTNER.key.toLowerCase()}`,
        text: modules.PARTNER.value,
      },
      {
        icon: <FontAwesomeIcon icon={faBoxArchive} />,
        moduleFeatures: {
          [modules.PRODUCT.key]: {
            module: modules.PRODUCT.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.PRODUCT.key.toLowerCase()}`,
        text: modules.PRODUCT.value,
      },
      {
        icon: <FontAwesomeIcon icon={faWeightHanging} />,
        moduleFeatures: {
          [modules.UNIT.key]: {
            module: modules.UNIT.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.UNIT.key.toLowerCase()}`,
        text: modules.UNIT.value,
      },
      {
        icon: <FontAwesomeIcon icon={faWarehouse} />,
        moduleFeatures: {
          [modules.WAREHOUSE.key]: {
            module: modules.WAREHOUSE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.WAREHOUSE.key.toLowerCase()}`,
        text: modules.WAREHOUSE.value,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faGears} />,
    isOpen: true,
    moduleFeatures: {
      [modules.PURCHASE_ORDER.key]: {
        module: modules.PURCHASE_ORDER.key,
        features: [features.READ_DATA],
      },
    },
    text: "Pembelian",
    children: [
      {
        icon: <FontAwesomeIcon icon={faBuildingCircleCheck} />,
        moduleFeatures: {
          [modules.PURCHASE_ORDER.key]: {
            module: modules.PURCHASE_ORDER.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.PURCHASE_ORDER.key.toLowerCase()}`,
        text: modules.PURCHASE_ORDER.value,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faCalculator} />,
    isOpen: true,
    moduleFeatures: {
      [modules.CALCULATE_BUDGET.key]: {
        module: modules.CALCULATE_BUDGET.key,
        features: [features.READ_DATA],
      },
      [modules.CALCULATE_HPP.key]: {
        module: modules.CALCULATE_HPP.key,
        features: [features.READ_DATA],
      },
    },
    text: "Perhitungan",
    children: [
      {
        icon: <FontAwesomeIcon icon={faMoneyBills} />,
        moduleFeatures: {
          [modules.CALCULATE_BUDGET.key]: {
            module: modules.CALCULATE_BUDGET.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.CALCULATE_BUDGET.key.toLowerCase()}`,
        text: modules.CALCULATE_BUDGET.value,
      },
      {
        icon: <FontAwesomeIcon icon={faFileShield} />,
        moduleFeatures: {
          [modules.CALCULATE_HPP.key]: {
            module: modules.CALCULATE_HPP.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.CALCULATE_HPP.key.toLowerCase()}`,
        text: modules.CALCULATE_HPP.value,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faGears} />,
    isOpen: true,
    moduleFeatures: {
      [modules.COMPANY_PROFILE.key]: {
        module: modules.COMPANY_PROFILE.key,
        features: [features.READ_DATA],
      },
      [modules.PBF.key]: {
        module: modules.PBF.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_USER.key]: {
        module: modules.MANAGE_USER.key,
        features: [features.READ_DATA],
      },
      [modules.MANAGE_USER_ROLE.key]: {
        module: modules.MANAGE_USER_ROLE.key,
        features: [features.READ_DATA],
      },
    },
    text: "Pengaturan",
    children: [
      {
        icon: <FontAwesomeIcon icon={faBuildingCircleCheck} />,
        moduleFeatures: {
          [modules.COMPANY_PROFILE.key]: {
            module: modules.COMPANY_PROFILE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.COMPANY_PROFILE.key.toLowerCase()}`,
        text: modules.COMPANY_PROFILE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faFileShield} />,
        moduleFeatures: {
          [modules.PBF.key]: {
            module: modules.PBF.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.PBF.key.toLowerCase()}`,
        text: modules.PBF.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUsersCog} />,
        moduleFeatures: {
          [modules.MANAGE_USER_ROLE.key]: {
            module: modules.MANAGE_USER_ROLE.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_USER_ROLE.key.toLowerCase()}`,
        text: modules.MANAGE_USER_ROLE.value,
      },
      {
        icon: <FontAwesomeIcon icon={faUserGear} />,
        moduleFeatures: {
          [modules.MANAGE_USER.key]: {
            module: modules.MANAGE_USER.key,
            features: [features.READ_DATA],
          },
        },
        path: `/admin/${modules.MANAGE_USER.key.toLowerCase()}`,
        text: modules.MANAGE_USER.value,
      },
    ],
  },
];
