import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import { creditDebtStatusOptions } from "../../../../constants/creditDebtStatusOptions";
import { flagOptions } from "../../../../constants/flagOptions";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { chartOfAccountSchema } from "../../../../schemas/chartOfAccountSchema";
import { getChartOfAccountById } from "../../../../services/admin/chartOfAccount/getChartOfAccountById";
import { updateChartOfAccount } from "../../../../services/admin/chartOfAccount/updateChartOfAccount";
import { getCoaCategory } from "../../../../services/admin/coaCategory/getCoaCategory";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import styles from "./ChartOfAccountDetail.module.scss";

const autocompletePosLevelOptions = [
  {
    id: 1,
    name: "AKUMULASI",
  },
  {
    id: 13,
    name: "NONE",
  },
  {
    id: 16,
    name: "PASSIVA",
  },
];
const autocompletePosSubLevelOptions = [
  {
    id: 1,
    pos_level_id: 12,
    name: "NONE",
  },
  {
    id: 2,
    pos_level_id: 13,
    name: "NONE",
  },
  {
    id: 3,
    pos_level_id: 16,
    name: "HUTANG DAGANG",
  },
  {
    id: 4,
    pos_level_id: 16,
    name: "HUTANG PAJAK",
  },
  {
    id: 5,
    pos_level_id: 16,
    name: "EKUITAS",
  },
];
const autocompleteGroupAccountOptions = [
  {
    id: 1,
    name: "Aktiva",
  },
  {
    id: 2,
    name: "Hutang",
  },
  {
    id: 3,
    name: "Modal",
  },
];

const ChartOfAccountDetail = () => {
  const { id } = useParams();
  const { control, getValues, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      coa_category: null,
      code: "",
      credit_debt_status: null, // ? debit / kredit
      flag: null, // ? neraca / laba_rugi
      group_account_1: null,
      group_account_2: null,
      group_account_3: null,
      name: "",
      pos_level: null,
      pos_sub_level: null,
      status: true,
    },
    resolver: yupResolver(chartOfAccountSchema),
  });
  const [autocompleteCoaCategoryOptions, setAutocompleteCoaCategoryOptions] =
    useState([]);
  const [isLoadingAutocompleteFlag, setIsLoadingAutocompleteFlag] =
    useState(false);
  const [
    isLoadingAutocompleteCoaCategory,
    setIsLoadingAutocompleteCoaCategory,
  ] = useState(false);
  const [
    isLoadingAutocompleteGroupAccount,
    setIsLoadingAutocompleteGroupAccount,
  ] = useState(false);
  const [isLoadingAutocompletePosLevel, setIsLoadingAutocompletePosLevel] =
    useState(false);
  const [
    isLoadingAutocompleteCreditDebtStatus,
    setIsLoadingAutocompleteCreditDebtStatus,
  ] = useState(false);
  const [
    isLoadingAutocompletePosSubLevel,
    setIsLoadingAutocompletePosSubLevel,
  ] = useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompleteFlag, setIsOpenAutocompleteFlag] = useState(false);
  const [isOpenAutocompleteCoaCategory, setIsOpenAutocompleteCoaCategory] =
    useState(false);
  const [isOpenAutocompleteGroupAccount1, setIsOpenAutocompleteGroupAccount1] =
    useState(false);
  const [isOpenAutocompleteGroupAccount2, setIsOpenAutocompleteGroupAccount2] =
    useState(false);
  const [isOpenAutocompleteGroupAccount3, setIsOpenAutocompleteGroupAccount3] =
    useState(false);
  const [isOpenAutocompletePosLevel, setIsOpenAutocompletePosLevel] =
    useState(false);
  const [
    isOpenAutocompleteCreditDebtStatus,
    setIsOpenAutocompleteCreditDebtStatus,
  ] = useState(false);
  const [isOpenAutocompletePosSubLevel, setIsOpenAutocompletePosSubLevel] =
    useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const fetchChartOfAccountById = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const coa_category = await getCoaCategory();
      const res = await getChartOfAccountById(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setValue(
          "coa_category",
          coa_category.payload.find((e) => e.id === res.payload.coa_category_id)
        );
        setValue("code", res.payload.code);
        setValue("name", res.payload.name);
        setValue(
          "credit_debt_status",
          creditDebtStatusOptions.find(
            (e) => e.id === res.payload.credit_debt_status
          )
        );
        setValue(
          "flag",
          flagOptions.find((e) => e.id === res.payload.flag)
        );
        setValue(
          "pos_level",
          autocompletePosLevelOptions.find(
            (e) => e.id === res.payload.pos_level
          )
        );
        setValue(
          "pos_sub_level",
          autocompletePosSubLevelOptions.find(
            (e) => e.id === res.payload.pos_sub_level
          )
        );
        setValue("status", res.payload.status);
        setValue(
          "group_account_1",
          autocompleteGroupAccountOptions.find(
            (e) => e.id === res.payload.group_account_1
          )
        );
        setValue(
          "group_account_2",
          autocompleteGroupAccountOptions.find(
            (e) => e.id === res.payload.group_account_2
          )
        );
        setValue(
          "group_account_3",
          autocompleteGroupAccountOptions.find(
            (e) => e.id === res.payload.group_account_3
          )
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchCoaCategory = async () => {
    try {
      setIsLoadingAutocompleteCoaCategory(true);

      const res = await getCoaCategory();

      if (res.status === 200) {
        setAutocompleteCoaCategoryOptions(res.payload);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingAutocompleteCoaCategory(false);
    }
  };

  const handleUpdateChartOfAccount = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.id = parseInt(id);
      data.coa_category_id = data.coa_category.id;
      data.credit_debt_status = data.credit_debt_status.id;
      data.flag = data.flag.id;
      data.group_account_1 = data.group_account_1?.id;
      data.group_account_2 = data.group_account_2?.id;
      data.group_account_3 = data.group_account_3?.id;
      data.pos_level = data.pos_level.id;
      data.pos_sub_level = data.pos_sub_level.id;

      const res = await updateChartOfAccount(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchChartOfAccountById();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    setAutocompleteCoaCategoryOptions([]);

    if (!isOpenAutocompleteCoaCategory) {
      return undefined;
    }

    fetchCoaCategory();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteCoaCategory]);

  useEffect(() => {
    fetchChartOfAccountById();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateChartOfAccount)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="coa_category"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.code + " - " + option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteCoaCategory}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteCoaCategory(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteCoaCategory(true);
                  }}
                  open={isOpenAutocompleteCoaCategory}
                  options={autocompleteCoaCategoryOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompleteCoaCategory ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Kategori COA"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="code"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="ID COA"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="name"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nama COA"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="credit_debt_status"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteCreditDebtStatus}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteCreditDebtStatus(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteCreditDebtStatus(true);
                  }}
                  open={isOpenAutocompleteCreditDebtStatus}
                  options={creditDebtStatusOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompleteCreditDebtStatus ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Status D/K"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="flag"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteFlag}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteFlag(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteFlag(true);
                  }}
                  open={isOpenAutocompleteFlag}
                  options={flagOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompleteFlag ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Flag Account"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="pos_level"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompletePosLevel}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    setValue("pos_sub_level", null);
                  }}
                  onClose={() => {
                    setIsOpenAutocompletePosLevel(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompletePosLevel(true);
                  }}
                  open={isOpenAutocompletePosLevel}
                  options={autocompletePosLevelOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompletePosLevel ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Level Pos"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="pos_sub_level"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  disabled={!getValues("pos_level")}
                  filterOptions={(options) =>
                    options.filter(
                      ({ pos_level_id }) =>
                        pos_level_id === getValues("pos_level.id")
                    )
                  }
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompletePosSubLevel}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompletePosSubLevel(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompletePosSubLevel(true);
                  }}
                  open={isOpenAutocompletePosSubLevel}
                  options={autocompletePosSubLevelOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompletePosSubLevel ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Sub Level Pos"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="status"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <>
                  <label className={styles["label__text"]}>Status</label>
                  <br />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={value}
                        onChange={(_, newValue) => {
                          onChange(newValue);
                        }}
                      />
                    }
                    label="Aktif"
                    labelPlacement="end"
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="group_account_1"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteGroupAccount}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteGroupAccount1(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteGroupAccount1(true);
                  }}
                  open={isOpenAutocompleteGroupAccount1}
                  options={autocompleteGroupAccountOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompleteGroupAccount ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Group Account 1"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="group_account_2"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteGroupAccount}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteGroupAccount2(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteGroupAccount2(true);
                  }}
                  open={isOpenAutocompleteGroupAccount2}
                  options={autocompleteGroupAccountOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompleteGroupAccount ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Group Account 2"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="group_account_3"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  loading={isLoadingAutocompleteGroupAccount}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteGroupAccount3(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteGroupAccount3(true);
                  }}
                  open={isOpenAutocompleteGroupAccount3}
                  options={autocompleteGroupAccountOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAutocompleteGroupAccount ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      label="Group Account 3"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonCreate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ChartOfAccountDetail;
