export const getPurchaseOrderDetailColumns = () => [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "product_name",
    headerName: "Nama Barang",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "product_code",
    headerName: "Kode Barang",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "quantity",
    headerName: "Kuantitas",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
];
