import * as yup from "yup";

export const companySchema = yup.object().shape({
  address: yup.string().required("Mohon mengisi Alamat Perusahaan."),
  business_registration_number: yup.string().required("Mohon mengisi NIB."),
  email: yup.string().required("Mohon mengisi Email."),
  fax: yup.string().required("Mohon mengisi Fax."),
  name: yup.string().required("Mohon mengisi Nama Perusahaan."),
  notarial_deed_number: yup
    .string()
    .required("Mohon mengisi Nomor Akta Notaris."),
  phone_number: yup.string().required("Mohon mengisi Nomor Telepon."),
  pic: yup.string().required("Mohon mengisi Penanggung Jawab."),
  pic_position: yup.string().required("Mohon mengisi Jabatan."),
  postal_code: yup.string().required("Mohon mengisi Kode Pos."),
  tax_identification_number: yup.string().required("Mohon mengisi NPWP."),
  ward: yup.string().required("Mohon mengisi Kelurahan."),
});
