import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { LoadingButton } from "@mui/lab";
import { Badge, Button, Grid, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { calculateBudgetSchema } from "../../../../schemas/calculateBudgetSchema";
import { createCalculateBudget } from "../../../../services/admin/calculateBudget/createCalculateBudget";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import CalculateHppText from "../../CalculateHpp/CalculateHppText";
import CalculateBudgetDialog from "../CalculateBudgetDialog";

const CalculateBudgetCreate = () => {
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      budget_total: 0,
      date: null,
      details: [],
      division: "",
      employee: "",
    },
    resolver: yupResolver(calculateBudgetSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "details",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const calculateBudgetDialogRef = useRef();
  const dialogContext = useContext(DialogContext);
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateCalculateBudget = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createCalculateBudget(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
        setAlertMessage("Success");
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleCreateCalculateBudget)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="employee"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nama"
                  onChange={onChange}
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="division"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  inputRef={ref}
                  label="Divisi"
                  onChange={onChange}
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="date"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                      variant: "standard",
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
              <Badge
                badgeContent={fields.length || null}
                color={errors.details ? "error" : "success"}
              >
                <Button
                  endIcon={<ChecklistIcon />}
                  loadingPosition="end"
                  onClick={() =>
                    calculateBudgetDialogRef.current.handleOpenDialog()
                  }
                  variant="contained"
                >
                  Detail Budget
                </Button>
              </Badge>
              <LoadingButton
                endIcon={<AddIcon />}
                loading={isLoadingButtonState.buttonCreate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Tambah Budget
              </LoadingButton>
            </Stack>
          </Grid>

          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`budget_total`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Budget"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <CalculateBudgetDialog
        append={append}
        control={control}
        fields={fields}
        getValues={getValues}
        ref={calculateBudgetDialogRef}
        remove={remove}
        setValue={setValue}
        title={"Budget"}
      />
    </>
  );
};

export default CalculateBudgetCreate;
