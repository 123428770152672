import * as yup from "yup";

export const employeeSchema = yup.object().shape({
  address: yup.string().required("Mohon mengisi Alamat."),
  blood_type: yup.string().required("Mohon mengisi Golongan Darah."),
  citizenship: yup.string().required("Mohon mengisi Kewarganegaraan."),
  date_of_birth: yup.string().required("Mohon mengisi Tanggal Lahir."),
  email: yup
    .string()
    .required("Mohon mengisi Email.")
    .email("Format Email Salah."),
  gender: yup.string().required("Mohon mengisi Jenis Kelamin."),
  identity_number: yup.string().required("Mohon mengisi NIK."),
  marital_status: yup.string().required("Mohon mengisi Alamat."),
  name: yup.string().required("Mohon mengisi Nama."),
  phone_number: yup.string().required("Mohon mengisi Nomor Telepon."),
  place_of_birth: yup.string().required("Mohon mengisi Tempat Lahir."),
  religion: yup.string().required("Mohon mengisi Agama."),
  rt_rw: yup.string().required("Mohon mengisi RT/RW."),
  subdistrict: yup.string().required("Mohon mengisi Kecamatan."), // kecamatan
  ward: yup.string().required("Mohon mengisi Kelurahan."), // kelurahan
});
