import { Box, Button, ThemeProvider } from "@mui/material";
import Lottie from "lottie-react";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContext from "../../contexts/ThemeContextProvider";
import styles from "./Forbidden.module.scss";
import forbiddenJSON from "./forbiddenJSON.json";

const Forbidden = ({ isShowButton }) => {
  const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);

  return (
    <ThemeProvider theme={themeContext.theme}>
      <div className={styles["forbidden__container"]}>
        <Lottie
          animationData={forbiddenJSON}
          className={styles["forbidden__animation"]}
          loop={true}
        />
        {isShowButton ? (
          <Box textAlign="center">
            <Button onClick={() => navigate(-2)} variant="outlined">
              Back
            </Button>
          </Box>
        ) : (
          <p className={styles["forbidden__text"]}>Forbidden</p>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Forbidden;
