import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getBankCashCategoryColumns = (
  dialogContext,
  handleDeleteBankCashCategory,
  handleUpdateBankCashCategory
) => [
  {
    field: "id",
    headerName: "ID",
    width: 90,
  },
  {
    field: "code",
    headerName: "Kode Kategori Kas Bank",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "name",
    headerName: "Nama Kategori Kas Bank",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateBankCashCategory(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteBankCashCategory(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
