import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGrid from "../../../../components/admin/AdminDataGrid";
import { getFpdDetailColumns } from "../../../../constants/columns/admin/fpd/getFpdDetailColumns";
import { getFpdPaymentColumns } from "../../../../constants/columns/admin/fpd/getFpdPaymentColumns";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { exportFpd } from "../../../../services/admin/fpd/exportFpd";
import { getFpdApproved } from "../../../../services/admin/fpd/getFpdApproved";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const FpdPaymentList = () => {
  const [dataGridDetailState, setDataGridDetailState] = useState({
    isLoading: false,
    rows: [],
  });
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchFpdApproved = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getFpdApproved();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleExportFpdPdf = async (id, number) => {
    try {
      setIsLoadingLinearProgress(true);

      await exportFpd(id, number);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleGetFpdDetails = (fpd_detail) => {
    setDataGridDetailState((prevState) => ({
      ...prevState,
      rows: fpd_detail,
    }));

    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleUpdateFpd = (id) => {
    navigate(`/admin/fpd_payment/${id}`);
  };

  useEffect(() => {
    fetchFpdApproved();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Dialog
        fullWidth
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog}
        open={isOpenDialog}
      >
        <DialogTitle>Detail FPD</DialogTitle>
        <Divider />
        <DialogContent>
          <AdminDataGrid
            columns={getFpdDetailColumns()}
            dataGridState={dataGridDetailState}
          />
        </DialogContent>
      </Dialog>
      <AdminDataGrid
        columns={getFpdPaymentColumns(
          handleExportFpdPdf,
          handleGetFpdDetails,
          handleUpdateFpd
        )}
        dataGridState={dataGridState}
      />
    </Paper>
  );
};

export default FpdPaymentList;
