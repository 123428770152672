import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import AdminDataGrid from "../../../../components/admin/AdminDataGrid";
import { getFpdJoinColumns } from "../../../../constants/columns/admin/fpd/getFpdJoinColumns";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { getFpdJoin } from "../../../../services/admin/fpd/getFpdJoin";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const FpdJoin = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchFpdJoin = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getFpdJoin();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        let temp = res.payload.map((element, index) => {
          return { id: index + 1, ...element };
        });

        setDataGridState((prevState) => ({
          ...prevState,
          rows: temp,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchFpdJoin();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGrid
      columns={getFpdJoinColumns()}
      dataGridState={dataGridState}
    />
  );
};

export default FpdJoin;
