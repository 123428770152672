import * as yup from "yup";

export const pbfSchema = yup.object().shape({
  address: yup.string().required("Mohon mengisi Alamat."),
  name: yup.string().required("Mohon mengisi Nama."),
  date: yup.date().required("Mohon mengisi Tanggal PBF."),
  date_of_birth: yup.date().required("Mohon mengisi Tanggal Lahir."),
  id_card_number: yup.string().required("Mohon mengisi Nomor KTP."),
  permit_number: yup.string().required("Mohon mengisi Nomor Izin PBF."),
  phone_number: yup.string().required("Mohon mengisi Nomor Telepon."),
  sipa_number: yup.string().required("Mohon mengisi Nomor SIPA."),
  sipa_date: yup.date().required("Mohon mengisi Tanggal SIPA."),
});
