import { modules } from "./modules";

export const landingPageOrder = {
  DASHBOARD: {
    key: modules.DASHBOARD.key,
    url: modules.DASHBOARD.key.toLowerCase(),
  },
  DEPARTMENT: {
    key: modules.DEPARTMENT.key,
    url: modules.DEPARTMENT.key.toLowerCase(),
  },
  EMPLOYEE: {
    key: modules.EMPLOYEE.key,
    url: modules.EMPLOYEE.key.toLowerCase(),
  },
};
