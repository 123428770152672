export const getEmployeeDropdown = async () => {
  return {
    payload: [
      {
        id: 1,
        name: "Naruto",
      },
      {
        id: 2,
        name: "Sasuke",
      },
    ],
  };
};
