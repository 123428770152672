import React from "react";
import styles from "./CalculateHppText.module.scss";

const CalculateHppText = ({ title, description }) => {
  return (
    <div className={styles["calculate-hpp-text"]}>
      <label>{title}</label>
      <div>{description}</div>
    </div>
  );
};

export default CalculateHppText;
