import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
} from "@mui/material";
import { createContext, forwardRef, useState } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContext = createContext();

const DialogContextProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    action: () => {},
    content: "",
    isOpen: false,
    title: "",
  });

  const handleCloseDialog = () => {
    setDialogState((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleOpenDialog = (action, content, title) => {
    setDialogState((prevState) => ({
      ...prevState,
      action: action,
      content: content,
      isOpen: true,
      title: title,
    }));
  };

  return (
    <DialogContext.Provider value={{ handleCloseDialog, handleOpenDialog }}>
      {children}
      <Dialog
        fullWidth
        keepMounted
        maxWidth="md"
        open={dialogState.isOpen}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogTitle>{dialogState.title}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>{dialogState.content}</DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              dialogState.action();
              handleCloseDialog();
            }}
          >
            Yes
          </Button>
          <Button onClick={handleCloseDialog}>No</Button>
        </DialogActions>
      </Dialog>
    </DialogContext.Provider>
  );
};

export { DialogContextProvider };
export default DialogContext;
