import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getCalculateBudgetColumns } from "../../../../constants/columns/admin/calculateBudget/getCalculateBudgetColumns";
import { getCalculateBudgetDetailColumns } from "../../../../constants/columns/admin/calculateBudget/getCalculateBudgetDetailColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteCalculateBudget } from "../../../../services/admin/calculateBudget/deleteCalculateBudget";
import { getCalculateBudget } from "../../../../services/admin/calculateBudget/getCalculateBudget";
import { getCalculateBudgetDetail } from "../../../../services/admin/calculateBudget/getCalculateBudgetDetail";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const CalculateBudgetList = () => {
  const [dataGridDetailState, setDataGridDetailState] = useState({
    filterModel: [],
    hID: 0,
    isLoading: false,
    pageIndex: 0,
    rowCount: 0,
    rows: [
      {
        id: 1,
        amount: 100000,
        title: "Beli Sabun",
        description: "Sabun lifeboy terwangi",
      },
      {
        id: 2,
        amount: 200000,
        title: "Beli Roti",
        description: "Sari Roti terbaik",
      },
      {
        id: "total",
        amount: 300000,
        title: "",
        description: "",
      },
    ],
    rowsPerPage: 25,
    sortModel: [],
  });
  const [dataGridState, setDataGridState] = useState({
    filterModel: [],
    isLoading: false,
    pageIndex: 0,
    rowCount: 0,
    rows: [
      {
        id: 1,
        name: "Budi",
        division: "Support",
      },
    ],
    rowsPerPage: 25,
    sortModel: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchCalculateBudget = async (
    filterModel,
    pageIndex,
    rowsPerPage,
    sortModel
  ) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        filterModel: filterModel,
        isLoading: true,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getCalculateBudget({
        filterModel: filterModel,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload.query,
          rowCount: res.payload.rowCount,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchCalculateBudgetDetail = async (
    filterModel,
    pageIndex,
    rowsPerPage,
    sortModel,
    id
  ) => {
    try {
      setDataGridDetailState((prevState) => ({
        ...prevState,
        filterModel: filterModel,
        isLoading: true,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getCalculateBudgetDetail({
        filterModel: filterModel,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
        id: id,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridDetailState((prevState) => ({
          ...prevState,
          rows: res.payload.query,
          rowCount: res.payload.rowCount,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridDetailState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleDeleteCalculateBudget = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteCalculateBudget(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchCalculateBudget(
          dataGridState.filterModel,
          dataGridState.pageIndex,
          dataGridState.rowsPerPage,
          dataGridState.sortModel
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleGetCalculateBudgetDetail = (id) => {
    fetchCalculateBudgetDetail(
      dataGridDetailState.filterModel,
      dataGridDetailState.pageIndex,
      dataGridDetailState.rowsPerPage,
      dataGridDetailState.sortModel,
      id
    );
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleUpdateCalculateBudget = (id) => {
    navigate(`/admin/calculate_budget/${id}`);
  };

  useEffect(() => {
    fetchCalculateBudget(
      dataGridState.filterModel,
      dataGridState.pageIndex,
      dataGridState.rowsPerPage,
      dataGridState.sortModel
    );

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog}
        open={isOpenDialog}
      >
        <DialogTitle>Detail Budget</DialogTitle>
        <Divider />
        <DialogContent>
          <DataGrid
            columns={getCalculateBudgetDetailColumns()}
            rows={dataGridDetailState.rows}
            sx={{ height: "50vh", minHeight: 400, width: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <DataGrid
        columns={getCalculateBudgetColumns(
          dialogContext,
          handleDeleteCalculateBudget,
          handleGetCalculateBudgetDetail,
          handleUpdateCalculateBudget
        )}
        rows={dataGridState.rows}
        sx={{ height: "70vh", minHeight: 600, width: "100%" }}
      />
    </>
  );
};

export default CalculateBudgetList;
