import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import ChartOfAccountCreate from "./ChartOfAccountCreate";
import ChartOfAccountList from "./ChartOfAccountList";

const ChartOfAccount = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label="Tambah COA" value="0" />
            <Tab label="Daftar COA" value="1" />
          </TabList>
        </Box>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.CHART_OF_ACCOUNT.key]: {
              module: modules.CHART_OF_ACCOUNT.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <TabPanel value="0">
            <ChartOfAccountCreate />
          </TabPanel>
        </AuthElement>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.CHART_OF_ACCOUNT.key]: {
              module: modules.CHART_OF_ACCOUNT.key,
              features: [features.READ_DATA],
            },
          }}
        >
          <TabPanel value="1">
            <ChartOfAccountList />
          </TabPanel>
        </AuthElement>
      </TabContext>
    </Paper>
  );
};

export default ChartOfAccount;
