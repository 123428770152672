import * as yup from "yup";

export const bankCashSchema = yup.object().shape({
  bank_cash_category: yup.object().required("Mohon memilih jenis kas bank."),
  date: yup.date().required("Mohon mengisi tanggal."),
  description: yup.string(),
  division: yup.object().required("Mohon memilih divisi."),
  invoice_number: yup.object().notRequired(),
  transaction_type: yup.object().required("Mohon memilih jenis transaksi."),
  voucher_number: yup.string(),
});
