const env = process.env.REACT_APP_NODE_ENV;

const url = {
  development: {
    BASE_URL_BE: "http://127.0.0.1:8092",
    BASE_URL_FE: "http://127.0.0.1:3000",
  },
  trial: {
    BASE_URL_BE: "https://trial.be.saleshubcloud.com",
    BASE_URL_FE: "https://trial.saleshubcloud.com",
  },
  production: {
    BASE_URL_BE: "https://be.saleshubcloud.com",
    BASE_URL_FE: "https://saleshubcloud.com",
  },
};

export const BASE_URL_BE = url[env].BASE_URL_BE;
export const BASE_URL_FE = url[env].BASE_URL_FE;
