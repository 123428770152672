import React from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { adminDrawerItems } from "../constants/adminDrawerItems";

const RoleMiddleware = () => {
  let isAuth = false;
  const location = useLocation();
  const outletContext = useOutletContext();
  const userRoleJson = localStorage.getItem("user_role_json")
    ? JSON.parse(localStorage.getItem("user_role_json"))
    : [];

  for (let i = 0; i < adminDrawerItems.length; i++) {
    const drawerItem = adminDrawerItems[i];
    const split = location.pathname.split("/");
    const tempInteger = parseInt(split[split.length - 1]);
    let url = "";

    if (!isNaN(tempInteger)) {
      split.pop();
      url = split.join("/");
    }

    if (!drawerItem.children) {
      if (location.pathname === drawerItem.path || url === drawerItem.path) {
        for (let k = 0; k < userRoleJson.length; k++) {
          const userRoleValue = userRoleJson[k];
          const drawerItemValue =
            drawerItem.moduleFeatures[userRoleValue.module];

          if (!isAuth && drawerItemValue) {
            isAuth = userRoleValue.features.some(
              (r) => drawerItemValue.features.indexOf(r) >= 0
            );
          }
        }
      }
    } else {
      for (let j = 0; j < drawerItem.children.length; j++) {
        const drawerChild = drawerItem.children[j];

        if (
          location.pathname === drawerChild.path ||
          url === drawerChild.path
        ) {
          for (let k = 0; k < userRoleJson.length; k++) {
            const userRoleValue = userRoleJson[k];
            const drawerChildValue =
              drawerChild.moduleFeatures[userRoleValue.module];

            if (!isAuth && drawerChildValue) {
              isAuth = userRoleValue.features.some(
                (r) => drawerChildValue.features.indexOf(r) >= 0
              );
            }
          }
        }
      }
    }
  }

  // TODO : uncomment
  isAuth = true;

  return isAuth ? (
    <Outlet context={outletContext} />
  ) : (
    <Navigate to="/admin/403" />
  );
};

export default RoleMiddleware;
