import * as yup from "yup";

export const purchaseOrderSchema = yup.object().shape({
  code: yup.string().required("Mohon mengisi kode po."),
  date: yup.date().required("Mohon mengisi tanggal."),
  discount_percentage: yup.number().notRequired(),
  down_payment: yup.number().notRequired(),
  dpp: yup.number().required("Mohon mengisi dpp."),
  invoice_discount: yup.number().notRequired(),
  partner: yup.object().required("Mohon memilih pelanggan."),
  pph22: yup.number().notRequired(),
  ppn: yup.number().notRequired(),
  sale_price: yup.number().required("Mohon mengisi harga jual."),
  purchase_order_detail: yup.array().of(
    yup.object().shape({
      product: yup.object().required("Mohon memilih produk."),
      quantity: yup.number().required("Mohon mengisi kuantitas."),
    })
  ),
});
