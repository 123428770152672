import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Grid, Stack, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { companySchema } from "../../../../schemas/companySchema";
import { createCompany } from "../../../../services/admin/company/createCompany";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const CompanyCreate = () => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      address: "",
      business_registration_number: "",
      email: "",
      fax: "",
      name: "",
      notarial_deed_number: "",
      phone_number: "",
      pic: "",
      pic_position: "",
      postal_code: "",
      tax_identification_number: "",
      ward: "",
    },
    resolver: yupResolver(companySchema),
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateCompany = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createCompany(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateCompany)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Perusahaan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Alamat Perusahaan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="tax_identification_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="NPWP"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="business_registration_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="NIB"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="notarial_deed_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nomor Akta Notaris"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="ward"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kelurahan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="postal_code"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kode Pos"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="phone_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nomor Telepon"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="fax"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Fax"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Email"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="pic"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Penanggung Jawab"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="pic_position"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Jabatan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyCreate;
