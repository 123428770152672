export const modules = {
  BANK_CASH: { key: "BANK_CASH", value: "Kas Bank" },
  BANK_CASH_CATEGORY: { key: "BANK_CASH_CATEGORY", value: "Kategori Kas Bank" },
  CALCULATE_BUDGET: { key: "CALCULATE_BUDGET", value: "Menghitung Budget" },
  CALCULATE_HPP: { key: "CALCULATE_HPP", value: "Menghitung HPP" },
  CHART_OF_ACCOUNT: { key: "CHART_OF_ACCOUNT", value: "COA" },
  COA_CATEGORY: { key: "COA_CATEGORY", value: "Kategori COA" },
  COMPANY: { key: "COMPANY", value: "Perusahaan" },
  COMPANY_PROFILE: { key: "COMPANY_PROFILE", value: "Profil Perusahaan" },
  CURRENCY: { key: "CURRENCY", value: "Mata Uang" },
  DASHBOARD: { key: "DASHBOARD", value: "Dasbor" },
  DEPARTMENT: { key: "DEPARTMENT", value: "Departemen" },
  DIVISION: { key: "DIVISION", value: "Divisi" },
  EMPLOYEE: { key: "EMPLOYEE", value: "Karyawan" },
  FPD: { key: "FPD", value: "FPD" },
  FPD_APPROVAL: { key: "FPD_APPROVAL", value: "Persetujuan FPD" },
  FPD_PAYMENT: { key: "FPD_PAYMENT", value: "Pelunasan FPD" },
  MANAGE_USER: { key: "MANAGE_USER", value: "Atur Pengguna" },
  MANAGE_USER_ROLE: { key: "MANAGE_USER_ROLE", value: "Atur Peran" },
  PARTNER: { key: "PARTNER", value: "Rekanan" },
  PBF: { key: "PBF", value: "Izin PBF" },
  PURCHASE_ORDER: { key: "PURCHASE_ORDER", value: "Pesanan Pembelian" },
  PRODUCT: { key: "PRODUCT", value: "Barang" },
  UNIT: { key: "UNIT", value: "Satuan" },
  WAREHOUSE: { key: "WAREHOUSE", value: "Gudang" },
};
