import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminDrawerItems } from "../../../constants/adminDrawerItems";
import AuthElement from "../../AuthElement";

const DrawerItems = () => {
  const [adminDrawerItem, setAdminDrawerItem] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClickDrawerItem = (value) => {
    if (!value.children) {
      navigate(value.path);
    } else {
      const tempAdminDrawerItem = adminDrawerItem.map((item) => {
        if (value === item) {
          item.isOpen = !item.isOpen;
        }
        return item;
      });

      setAdminDrawerItem(tempAdminDrawerItem);
    }
  };

  const initDrawerListItems = () => {
    setAdminDrawerItem(adminDrawerItems);
  };

  const isDrawerItemSelected = (path) => {
    const split = location.pathname.split("/");
    const tempInteger = parseInt(split[split.length - 1]);
    let url = "";

    if (!isNaN(tempInteger)) {
      split.pop();
      url = split.join("/");
    }

    if (location.pathname === path || url === path) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    initDrawerListItems();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <List
      sx={{
        "& ul": { padding: 0 },
        bgcolor: "background.paper",
        height: "100vh",
        position: "relative",
        overflow: "auto",
        width: "100%",
      }}
      subheader={<li />}
    >
      {adminDrawerItem.map((drawerItem, index) => {
        return (
          <li key={index}>
            <ul>
              {drawerItem.children ? (
                <AuthElement moduleFeatures={drawerItem.moduleFeatures}>
                  {index > 0 && !adminDrawerItem[index - 1].children && (
                    <Divider />
                  )}
                  <ListSubheader sx={{ p: 0 }}>
                    <ListItemButton
                      onClick={() => handleClickDrawerItem(drawerItem)}
                      selected={isDrawerItemSelected(drawerItem.path)}
                    >
                      <ListItemIcon sx={{ justifyContent: "center" }}>
                        {drawerItem.icon}
                      </ListItemIcon>
                      <ListItemText primary={drawerItem.text} />
                      {drawerItem.isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListSubheader>
                </AuthElement>
              ) : (
                <AuthElement moduleFeatures={drawerItem.moduleFeatures}>
                  <ListItemButton
                    onClick={() => handleClickDrawerItem(drawerItem)}
                    selected={isDrawerItemSelected(drawerItem.path)}
                    sx={{
                      "&&.Mui-selected": {
                        bgcolor: theme.palette.action.selected,
                      },
                      "&:focus": {
                        bgcolor: theme.palette.action.hover,
                      },
                      "&:hover": {
                        bgcolor: theme.palette.action.hover,
                      },
                    }}
                  >
                    <ListItemIcon sx={{ justifyContent: "center" }}>
                      {drawerItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={drawerItem.text} />
                  </ListItemButton>
                </AuthElement>
              )}
              {drawerItem.children && (
                <>
                  <Collapse in={drawerItem.isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {drawerItem.children.map((drawerChild, index) => (
                        <AuthElement
                          key={index}
                          moduleFeatures={drawerChild.moduleFeatures}
                        >
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => handleClickDrawerItem(drawerChild)}
                              selected={isDrawerItemSelected(drawerChild.path)}
                              sx={{
                                "&&.Mui-selected": {
                                  bgcolor: theme.palette.action.selected,
                                },
                                "&:focus": {
                                  bgcolor: theme.palette.action.hover,
                                },
                                "&:hover": {
                                  bgcolor: theme.palette.action.hover,
                                },
                                pl: 3.5,
                              }}
                            >
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                {drawerChild.icon}
                              </ListItemIcon>
                              <ListItemText primary={drawerChild.text} />
                            </ListItemButton>
                          </ListItem>
                        </AuthElement>
                      ))}
                    </List>
                  </Collapse>
                  <AuthElement moduleFeatures={drawerItem.moduleFeatures}>
                    <Divider />
                  </AuthElement>
                </>
              )}
            </ul>
          </li>
        );
      })}
    </List>
  );
};

export default DrawerItems;
