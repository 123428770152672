import * as yup from "yup";

export const calculateHppSchema = yup.object().shape({
  body: yup.array().of(
    yup.object().shape({
      price: yup.number().required("Mohon mengisi Harga."),
      product: yup.string().required("Mohon mengisi Nama."),
      weight: yup.number().required("Mohon mengisi Berat."),
      composition: yup.number(),
      qty_in_percent: yup.number(),
      ppb7: yup.number(),
      ppb7_ppn: yup.number(),
      ppb7_no_ppn: yup.number(),
    })
  ),
  body_total_weight: yup.number(),
  body_total_composition: yup.number(),
  body_total_qty_in_percent: yup.number(),
  body_total_ppb7_no_ppn: yup.number(),
  body_total_yield: yup.number(),
  distributor_disc: yup
    .number()
    .required("Mohon mengisi Disc. Distributor (%)."),
  distributor_disc_total: yup.number(),
  employee_rate: yup.number().required("Mohon mengisi Karyawan."),
  ex_work: yup.number(),
  formula_code: yup.string().required("Mohon mengisi Kode Formula."),
  foh_rate: yup.number().required("Mohon mengisi FOH."),
  gross_margin: yup.number(),
  logistic_price: yup.number().required("Mohon mengisi Biaya Logistik (%)."),
  logistic_price_total: yup.number(),
  margin: yup.number().required("Mohon mengisi Margin (%)."),
  margin_total: yup.number(),
  others_total: yup.number(),
  packages: yup.array().of(
    yup.object().shape({
      product: yup.string().required("Mohon mengisi Nama."),
      qty: yup.number().required("Mohon mengisi Jumlah."),
      price: yup.number().required("Mohon mengisi Harga."),
    })
  ),
  packages_total_ppb7_no_ppn: yup.number(),
  packaging: yup.number().required("Mohon mengisi Kemasan."),
  product_code: yup.string().required("Mohon mengisi Kode Produk."),
  product_name: yup.string().required("Mohon mengisi Nama Produk."),
  price_per_carton: yup.number(),
  price_per_carton_ppn: yup.number(),
  resources_rate: yup.number().required("Mohon mengisi Listrik + Air."),
  revision: yup.number().required("Mohon mengisi Revisi."),
  status: yup.string().required("Mohon mengisi Status."),
  trial_date: yup.string().required("Mohon mengisi Tanggal Trial."),
  yield: yup.number().required("Mohon mengisi Rendeman."),
});
