import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { warehouseSchema } from "../../../../schemas/warehouseSchema";
import { getEmployeeDropdown } from "../../../../services/employee/getEmployeeDropdown";
import { createWarehouse } from "../../../../services/warehouse/createWarehouse";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const WarehouseCreate = () => {
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
      address: "",
      employee: null,
    },
    resolver: yupResolver(warehouseSchema),
  });
  const [autocompleteEmployeeOptions, setAutocompleteEmployeeOptions] =
    useState([]);
  const [isLoadingAutocompleteEmployee, setIsLoadingAutocompleteEmployee] =
    useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompleteEmployee, setIsOpenAutocompleteEmployee] =
    useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateWarehouse = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createWarehouse(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete employee
  useEffect(() => {
    setAutocompleteEmployeeOptions([]);

    if (!isOpenAutocompleteEmployee) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteEmployee(true);

        const res = await getEmployeeDropdown();

        setAutocompleteEmployeeOptions(res.payload);
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteEmployee(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteEmployee]);

  return (
    <form onSubmit={handleSubmit(handleCreateWarehouse)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="code"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kode"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Alamat"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="employee"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value}
                loading={isLoadingAutocompleteEmployee}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteEmployee(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteEmployee(true);
                }}
                open={isOpenAutocompleteEmployee}
                options={autocompleteEmployeeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingAutocompleteEmployee ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    label="Kepala Gudang"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default WarehouseCreate;
