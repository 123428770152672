import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import { cityOptions } from "../../../cityOptions";
import { genderOptions } from "../../../genderOptions";
import { partnerTypeOptions } from "../../../partnerTypeOptions";

export const getPartnerColumns = (
  dialogContext,
  handleDeletePartner,
  handleUpdatePartner
) => [
  {
    field: "id",
    headerName: "ID",
    width: 90,
  },
  {
    field: "code",
    headerName: "Kode",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "name",
    headerName: "Nama Rekanan",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "nik",
    headerName: "NIK",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "date_of_birth",
    flex: 3,
    headerName: "Tanggal Lahir",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "gender",
    headerName: "Jenis Kelamin",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return genderOptions.find((e) => e.id === row.gender)?.name ?? "";
    },
  },
  {
    field: "partner_type",
    headerName: "Tipe Rekanan",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return (
        partnerTypeOptions.find((e) => e.id === row.partner_type)?.name ?? ""
      );
    },
  },
  {
    field: "place_of_birth",
    headerName: "Tempat Lahir",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return cityOptions.find((e) => e.id === row.place_of_birth)?.name ?? "";
    },
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdatePartner(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeletePartner(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
