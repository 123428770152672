import * as yup from "yup";

export const partnerSchema = yup.object().shape({
  code: yup.string().required("Mohon mengisi kode rekanan"),
  date_of_birth: yup.date().required("Mohon mengisi tanggal lahir"),
  gender: yup.object().required("Mohon mengisi jenis kelamin"),
  name: yup.string().required("Mohon mengisi nama"),
  nik: yup.string().required("Mohon mengisi nik"),
  partner_type: yup.object().required("Mohon mengisi tipe rekanan"),
  place_of_birth: yup.object().required("Mohon mengisi tempat lahir"),
  // address: yup.string().required("Mohon mengisi Alamat."),
  // blood_type: yup.string().required("Mohon mengisi Golongan Darah."),
  // business_registration_number: yup.string().required("Mohon mengisi NIB."),
  // citizenship: yup.string().required("Mohon mengisi Kewarganegaraan."),
  // date_of_birth: yup.string().required("Mohon mengisi Tanggal Lahir."),
  // email: yup
  //   .string()
  //   .required("Mohon mengisi Email.")
  //   .email("Format Email Salah."),
  // gender: yup.string().required("Mohon mengisi Jenis Kelamin."),
  // identity_number: yup.string().required("Mohon mengisi NIK."),
  // marital_status: yup.string().required("Mohon mengisi Alamat."),
  // name: yup.string().required("Mohon mengisi Nama."),
  // notarial_deed_number: yup
  //   .string()
  //   .required("Mohon mengisi Nomor Akta Notaris."),
  // phone_number: yup.string().required("Mohon mengisi Nomor Telepon."),
  // place_of_birth: yup.string().required("Mohon mengisi Tempat Lahir."),
  // religion: yup.string().required("Mohon mengisi Agama."),
  // rt_rw: yup.string().required("Mohon mengisi RT/RW."),
  // subdistrict: yup.string().required("Mohon mengisi Kecamatan."),
  // tax_identification_number: yup.string().required("Mohon mengisi NPWP."),
  // type: yup.string().required("Mohon memilih Golongan Rekanan."),
  // ward: yup.string().required("Mohon mengisi Kelurahan."),
});
