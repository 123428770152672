import { format } from "date-fns";
import { transactionTypeOptions } from "../../../transactionTypeOptions";

export const getFpdJoinColumns = (handleExportPdf) => [
  {
    field: "id",
    headerName: "No",
    width: 90,
  },
  {
    field: "date",
    flex: 3,
    headerName: "Tanggal",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "payment_date",
    flex: 3,
    headerName: "Tanggal Bayar",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "number",
    headerName: "Nomor FPD",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "voucher_number",
    headerName: "Nomor Voucher",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "coa_code",
    headerName: "Kas / bank",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return row.coa_code + " - " + row.coa_name;
    },
  },
  {
    field: "fpd_d_description",
    headerName: "Keterangan",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "transaction_type",
    headerName: "Jenis Transaksi",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return (
        transactionTypeOptions.find((e) => e.id === row.transaction_type)
          ?.name ?? ""
      );
    },
  },
  {
    field: "fpd_d_debt",
    headerName: "Debet",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "fpd_d_credit",
    headerName: "Kredit",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "fpd_d_amount",
    headerName: "Saldo",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
];
