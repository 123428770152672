function deleteAllCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++)
    deleteCookie(cookies[i].split("=")[0]);
}

function deleteCookie(name) {
  setCookie(name, "", -1);
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function setCookie(name, value, exp) {
  var expires = "";

  if (exp) {
    var date = new Date();
    // cookie by second
    date.setTime(date.getTime() + exp * 1000);
    // cookie by day
    // date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  }

  document.cookie = name + "=" + value + expires + "; path=/";
}

const cookie = {
  deleteAllCookies,
  deleteCookie,
  getCookie,
  setCookie,
};

export default cookie;
