import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import { transactionTypeOptions } from "../../../transactionTypeOptions";

export const getFpdPaymentColumns = (
  handleExportFpdPdf,
  handleGetFpdDetails,
  handleUpdateFpd
) => [
  {
    field: "id",
    headerName: "ID",
    width: 90,
  },
  {
    field: "date",
    flex: 3,
    headerName: "Tanggal",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "payment_date",
    flex: 3,
    headerName: "Tanggal Bayar",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "code",
    headerName: "Nomor FPD",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "voucher_number",
    headerName: "Nomor Voucher",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "division_name",
    headerName: "Nama Divisi",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.division?.name ?? "";
    },
  },
  {
    field: "transaction_type",
    headerName: "Jenis Transaksi",
    flex: 3,
    minWidth: 200,
    valueGetter: (_, row) => {
      return (
        transactionTypeOptions.find((e) => e.id === row.transaction_type)
          ?.name ?? ""
      );
    },
  },
  {
    field: "description",
    headerName: "Keterangan",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "is_approved",
    headerName: "Status Persetujuan",
    flex: 3,
    minWidth: 200,
    type: "boolean",
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<InfoIcon color="action" />}
        label="Detail"
        onClick={() => {
          handleGetFpdDetails(params.row.fpd_detail);
        }}
      />,
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateFpd(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<FileDownloadIcon color="warning" />}
        label="Export"
        onClick={() => {
          handleExportFpdPdf(params.row.id, params.row.code);
        }}
      />,
    ],
  },
];
