import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getCalculateBudgetColumns = (
  dialogContext,
  handleDeleteCalculateBudget,
  handleGetCalculateBudgetDetails,
  handleUpdateCalculateBudget
) => [
  {
    field: "id",
    headerName: "No",
    width: 150,
  },
  {
    field: "name",
    headerName: "Nama",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "division",
    headerName: "Division",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<InfoIcon color="action" />}
        label="Detail"
        onClick={() => {
          handleGetCalculateBudgetDetails(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateCalculateBudget(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteCalculateBudget(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
