import * as yup from "yup";

export const chartOfAccountSchema = yup.object().shape({
  coa_category: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .required("Mohon mengisi Kategori COA"),
  code: yup.string().required("Mohon mengisi ID COA."),
  credit_debt_status: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .required("Mohon memilih Status Debit / Kredit."),
  flag: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .required("Mohon memilih Flag."),
  group_account_1: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .notRequired(),
  group_account_2: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .notRequired(),
  group_account_3: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .notRequired(),
  name: yup.string().required("Mohon mengisi Nama."),
  pos_level: yup
    .object()
    .shape({
      id: yup.number(),
      name: yup.string(),
    })
    .required("Mohon memilih Level Pos."),
  pos_sub_level: yup
    .object()
    .shape({
      id: yup.number(),
      level_pos_id: yup.number(),
      name: yup.string(),
    })
    .required("Mohon memilih Sub Level Pos."),
  status: yup.bool(),
});
