import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";

export const getBankCashAccountColumns = (
  handleCancelClick,
  handleDeleteClick,
  handleEditClick,
  handleSaveClick,
  props,
  rowModesModel
) => [
  {
    field: "id",
    headerName: "ID",
    minWidth: 200,
  },
  {
    editable: true,
    field: "coa_id",
    flex: 1,
    headerName: "ID Akun",
    minWidth: 200,
    type: "singleSelect",
    valueOptions: props.coa_code,
    getOptionLabel: (value) => value.code + " - " + value.name,
    getOptionValue: (value) => value.id,
  },
  {
    editable: true,
    flex: 1,
    field: "description",
    headerName: "Keterangan",
    minWidth: 200,
  },
  {
    editable: true,
    flex: 1,
    field: "amount",
    headerName: "Saldo Transaksi",
    minWidth: 200,
    type: "number",
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    },
  },
];
