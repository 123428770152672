import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { getProduct } from "../../../../services/admin/product/getProduct";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const PurchaseOrderDialog = forwardRef(function (
  { append, control, fields, remove, title },
  ref
) {
  const [autocompleteProductOptions, setAutocompleteProductOptions] = useState(
    []
  );
  const [isLoadingAutocompleteProduct, setIsLoadingAutocompleteProduct] =
    useState(false);
  const [isOpenAutocompleteProduct, setIsOpenAutocompleteProduct] =
    useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState("0");
  const snackbarContext = useContext(SnackbarContext);

  const handleAddPurchaseOrderDetail = () => {
    append({
      product: null,
      quantity: 0,
    });
  };

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleRemovePurchaseOrderDetail = (index) => {
    remove(index);
    index -= 1;
    if (index < 0) {
      index = 0;
    }
    setTabValue(index.toString());
  };

  useImperativeHandle(
    ref,
    () => {
      return { handleCloseDialog, handleOpenDialog };
    },
    []
  );

  // autocomplete product
  useEffect(() => {
    setAutocompleteProductOptions([]);

    if (!isOpenAutocompleteProduct) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteProduct(true);

        const res = await getProduct();

        if (res.status === 200) {
          setAutocompleteProductOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteProduct(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteProduct]);

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth="md"
      onClose={handleCloseDialog}
      open={isOpenDialog}
    >
      <DialogTitle>Detail {title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Paper sx={{ borderRadius: 0 }}>
          {fields.length <= 0 ? (
            <Typography sx={{ p: 3, textAlign: "center" }}>No Data</Typography>
          ) : (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  allowScrollButtonsMobile
                  onChange={handleChangeTabValue}
                  scrollButtons
                  variant="scrollable"
                >
                  {fields.map((item, index) => {
                    return (
                      <Tab
                        key={item.id}
                        label={`${title} ${index + 1}`}
                        value={index.toString()}
                      />
                    );
                  })}
                </TabList>
              </Box>
              {fields.map((item, index) => {
                return (
                  <TabPanel key={item.id} value={index.toString()}>
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`purchase_order_detail.${index}.product`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <Autocomplete
                              getOptionLabel={(option) => option.name}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              loading={isLoadingAutocompleteProduct}
                              onChange={(_, newValue) => {
                                onChange(newValue);
                              }}
                              onClose={() => {
                                setIsOpenAutocompleteProduct(false);
                              }}
                              onOpen={() => {
                                setIsOpenAutocompleteProduct(true);
                              }}
                              open={isOpenAutocompleteProduct}
                              options={autocompleteProductOptions}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!error}
                                  helperText={error?.message}
                                  inputRef={ref}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {isLoadingAutocompleteProduct ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                  label="Produk"
                                />
                              )}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`purchase_order_detail.${index}.quantity`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <NumericFormat
                              allowNegative={false}
                              customInput={TextField}
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputProps={{ inputMode: "numeric" }}
                              inputRef={ref}
                              label="Kuantitas"
                              onValueChange={(values) => {
                                onChange(values.floatValue);
                              }}
                              thousandSeparator=","
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                );
              })}
            </TabContext>
          )}
        </Paper>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 0, sm: 2 }}
        >
          {fields.length > 0 && (
            <Button
              color="error"
              endIcon={<DeleteIcon />}
              onClick={() =>
                handleRemovePurchaseOrderDetail(parseInt(tabValue))
              }
              variant="text"
            >
              Hapus {title} {parseInt(tabValue) + 1}
            </Button>
          )}
          <Button
            endIcon={<AddIcon />}
            onClick={handleAddPurchaseOrderDetail}
            variant="text"
          >
            Tambah {title}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default PurchaseOrderDialog;
