import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getDepartmentColumns } from "../../../../constants/columns/admin/department/getDepartmentColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteDepartment } from "../../../../services/admin/department/deleteDepartment";
import { getDepartment } from "../../../../services/admin/department/getDepartment";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const DepartmentList = () => {
  const [dataGridState, setDataGridState] = useState({
    filterModel: [],
    isLoading: false,
    pageIndex: 0,
    rowCount: 0,
    rows: [{ id: 1, name: "asd" }],
    rowsPerPage: 25,
    sortModel: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchDepartment = async (
    filterModel,
    pageIndex,
    rowsPerPage,
    sortModel
  ) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        filterModel: filterModel,
        isLoading: true,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getDepartment({
        filterModel: filterModel,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload.query,
          rowCount: res.payload.rowCount,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteDepartment = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteDepartment(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchDepartment(
          dataGridState.filterModel,
          dataGridState.pageIndex,
          dataGridState.rowsPerPage,
          dataGridState.sortModel
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateDepartment = (id) => {
    navigate(`/admin/department/${id}`);
  };

  useEffect(() => {
    fetchDepartment(
      dataGridState.filterModel,
      dataGridState.pageIndex,
      dataGridState.rowsPerPage,
      dataGridState.sortModel
    );

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <DataGrid
      columns={getDepartmentColumns(
        dialogContext,
        handleDeleteDepartment,
        handleUpdateDepartment
      )}
      rowId="id"
      rows={dataGridState.rows}
      sx={{ height: "70vh", minHeight: 600, width: "100%" }}
    />
  );
};

export default DepartmentList;
