import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import PartnerCreate from "./PartnerCreate";
import PartnerList from "./PartnerList";

const Partner = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label="Tambah Rekanan" value="0" />
            <Tab label="Daftar Rekanan" value="1" />
          </TabList>
        </Box>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.PARTNER.key]: {
              module: modules.PARTNER.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <TabPanel value="0">
            <PartnerCreate />
          </TabPanel>
        </AuthElement>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.PARTNER.key]: {
              module: modules.PARTNER.key,
              features: [features.READ_DATA],
            },
          }}
        >
          <TabPanel value="1">
            <PartnerList />
          </TabPanel>
        </AuthElement>
      </TabContext>
    </Paper>
  );
};

export default Partner;
