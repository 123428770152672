import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import CalculateHppText from "../CalculateHppText";

const CalculateHppBodyDialog = forwardRef(function (
  {
    append,
    calculateExWork,
    control,
    fields,
    getValues,
    remove,
    setValue,
    title,
  },
  ref
) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState("0");

  const handleAddCalculateHppBodyDetails = () => {
    append({
      price: 0,
      product: "",
      weight: 0,
      composition: 0,
      qty_in_percent: 0,
      ppb7: 0,
      ppb7_ppn: 0,
      ppb7_no_ppn: 0,
    });

    calculateBody();
  };

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleRemoveCalculateHppBodyDetail = (index) => {
    remove(index);
    index -= 1;
    if (index < 0) {
      index = 0;
    }
    setTabValue(index.toString());

    calculateBody();
  };

  useImperativeHandle(
    ref,
    () => {
      return { handleCloseDialog, handleOpenDialog };
    },
    []
  );

  const calculateBody = () => {
    const body = getValues().body;

    let totalWeight = 0;
    let totalComposition = 0;
    let totalQtyInPercent = 0;
    let totalPpb7NoPpn = 0;

    for (let i = 0; i < body.length; i++) {
      totalWeight += body[i].weight;
    }

    if (totalWeight > 0) {
      for (let j = 0; j < body.length; j++) {
        const composition = body[j].weight / totalWeight;

        setValue(`body.${j}.composition`, composition);
        totalComposition += composition;

        const qtyInPercent = composition * 100;
        setValue(`body.${j}.qty_in_percent`, qtyInPercent);
        totalQtyInPercent += qtyInPercent;

        const ppb7 = body[j].price / (1 - 0.07);
        setValue(`body.${j}.ppb7`, ppb7);

        const ppb7Ppn = ppb7 * 0.13 + ppb7;
        setValue(`body.${j}.ppb7_ppn`, ppb7Ppn);

        const ppb7NoPpn = ppb7 * composition;
        setValue(`body.${j}.ppb7_no_ppn`, ppb7NoPpn);
        totalPpb7NoPpn += ppb7NoPpn;
      }
    }

    setValue("body_total_weight", totalWeight);
    setValue("body_total_composition", totalComposition);
    setValue("body_total_qty_in_percent", totalQtyInPercent);
    setValue("body_total_ppb7_no_ppn", parseInt(totalPpb7NoPpn.toFixed()));

    let yieldd = 1 + (getValues().yield ?? 0) / 100;
    setValue("body_total_yield", parseInt((totalPpb7NoPpn * yieldd).toFixed()));

    calculateExWork();
  };

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth="md"
      onClose={handleCloseDialog}
      open={isOpenDialog}
    >
      <DialogTitle>Detail {title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent sx={{ p: 0 }}>
        <Paper sx={{ borderRadius: 0 }}>
          {fields.length <= 0 ? (
            <Typography sx={{ p: 3, textAlign: "center" }}>No Data</Typography>
          ) : (
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  allowScrollButtonsMobile
                  onChange={handleChangeTabValue}
                  scrollButtons
                  variant="scrollable"
                >
                  {fields.map((item, index) => {
                    return (
                      <Tab
                        key={item.id}
                        label={`${title} ${index + 1}`}
                        value={index.toString()}
                      />
                    );
                  })}
                </TabList>
              </Box>
              {fields.map((item, index) => {
                return (
                  <TabPanel key={item.id} value={index.toString()}>
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.product`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputRef={ref}
                              label="Produk"
                              onChange={onChange}
                              value={value || ""}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.weight`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <NumericFormat
                              allowNegative={false}
                              customInput={TextField}
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputProps={{ inputMode: "numeric" }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    Kg
                                  </InputAdornment>
                                ),
                              }}
                              inputRef={ref}
                              label="Berat"
                              onValueChange={(values) => {
                                onChange(values.floatValue);
                                calculateBody();
                              }}
                              thousandSeparator=","
                              value={value}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.composition`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <CalculateHppText
                              title={"Komposisi"}
                              description={value?.toFixed(5)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.qty_in_percent`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <CalculateHppText
                              title={"Qty in %"}
                              description={value?.toFixed(3)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.price`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <NumericFormat
                              allowNegative={false}
                              customInput={TextField}
                              error={!!error}
                              fullWidth
                              helperText={error?.message}
                              inputProps={{ inputMode: "numeric" }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Rp
                                  </InputAdornment>
                                ),
                              }}
                              inputRef={ref}
                              label="Harga"
                              onValueChange={(values) => {
                                onChange(values.floatValue);
                                calculateBody();
                              }}
                              thousandSeparator=","
                              value={value}
                              variant="standard"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.ppb7`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <CalculateHppText
                              title={"Purchase price+Buffer 7%"}
                              description={new Intl.NumberFormat("id-ID", {
                                currency: "IDR",
                                style: "currency",
                              }).format(value ?? 0)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.ppb7_ppn`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <CalculateHppText
                              title={"Purchase price+Buffer 7%+PPN"}
                              description={new Intl.NumberFormat("id-ID", {
                                currency: "IDR",
                                style: "currency",
                              }).format(value ?? 0)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <Controller
                          control={control}
                          name={`body.${index}.ppb7_no_ppn`}
                          render={({
                            field: { onChange, ref, value },
                            fieldState: { error },
                          }) => (
                            <CalculateHppText
                              title={"Price + Buffer 7%  belum PPN"}
                              description={new Intl.NumberFormat("id-ID", {
                                currency: "IDR",
                                style: "currency",
                              }).format(value ?? 0)}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                );
              })}
            </TabContext>
          )}
        </Paper>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 0, sm: 2 }}
        >
          {fields.length > 0 && (
            <Button
              color="error"
              endIcon={<DeleteIcon />}
              onClick={() =>
                handleRemoveCalculateHppBodyDetail(parseInt(tabValue))
              }
              variant="text"
            >
              Hapus {title} {parseInt(tabValue) + 1}
            </Button>
          )}
          <Button
            endIcon={<AddIcon />}
            onClick={handleAddCalculateHppBodyDetails}
            variant="text"
          >
            Tambah {title}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
});

export default CalculateHppBodyDialog;
