import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";

export const getEmployeeColumns = (
  dialogContext,
  handleDeleteEmployee,
  handleUpdateEmployee
) => [
  {
    field: "id",
    headerName: "ID",
    width: 200,
  },
  {
    field: "name",
    headerName: "Nama",
    width: 200,
  },
  {
    field: "identity_number",
    headerName: "NIK",
    width: 200,
  },
  {
    field: "phone_number",
    headerName: "Nomor Telepon",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "date_of_birth",
    headerName: "Tempat/Tgl. Lahir",
    width: 200,
    valueGetter: (_, row) => {
      return `${row.place_of_birth} / ${format(
        row.date_of_birth,
        "dd-MM-yyyy"
      )}`;
    },
  },
  {
    field: "gender",
    headerName: "Jenis Kelamin",
    width: 200,
  },
  {
    field: "blood_type",
    headerName: "Gol. Darah",
    width: 200,
  },
  {
    field: "address",
    headerName: "Alamat",
    width: 200,
  },
  {
    field: "rt_rw",
    headerName: "RT/RW",
    width: 200,
  },
  {
    field: "ward",
    headerName: "Kel/Desa",
    width: 200,
  },
  {
    field: "subdistrict",
    headerName: "Kecamatan",
    width: 200,
  },
  {
    field: "religion",
    headerName: "Agama",
    width: 200,
  },
  {
    field: "marital_status",
    headerName: "Status Perkawinan",
    width: 200,
  },
  {
    field: "citizenship",
    headerName: "Kewarganegaraan",
    width: 200,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateEmployee(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteEmployee(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
