import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGrid from "../../../../components/admin/AdminDataGrid";
import { getBankCashCategoryColumns } from "../../../../constants/columns/admin/bankCashCategory.js/getBankCashCategoryColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteBankCashCategory } from "../../../../services/admin/bankCashCategory/deleteBankCashCategory";
import { getBankCashCategory } from "../../../../services/admin/bankCashCategory/getBankCashCategory";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const BankCashCategoryList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchBankCashCategory = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getBankCashCategory();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      setDataGridState((prevState) => ({
        ...prevState,
        rows: res.payload,
      }));
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };
  const handleDeleteBankCashCategory = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteBankCashCategory(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchBankCashCategory();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateBankCashCategory = (id) => {
    navigate(`/admin/bank_cash_category/${id}`);
  };

  useEffect(() => {
    fetchBankCashCategory();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGrid
      columns={getBankCashCategoryColumns(
        dialogContext,
        handleDeleteBankCashCategory,
        handleUpdateBankCashCategory
      )}
      dataGridState={dataGridState}
    />
  );
};

export default BankCashCategoryList;
