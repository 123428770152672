import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import DataGridCustomToolbar from "../DataGridCustomToolbar";

const AdminDataGrid = ({ columns, dataGridState }) => {
  return (
    <DataGrid
      columns={columns}
      disableRowSelectionOnClick
      keepNonExistentRowsSelected
      loading={dataGridState.isLoading}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      rows={dataGridState.rows}
      slots={{
        toolbar: DataGridCustomToolbar,
      }}
      sx={{ height: "70vh", minHeight: 600, width: "100%" }}
    />
  );
};

export default AdminDataGrid;
