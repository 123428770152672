import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getManageUserColumns = (
  dialogContext,
  handleDeleteManageUser,
  handleUpdateManageUser
) => [
  { field: "id", headerName: "ID", minWidth: 70 },
  {
    field: "username",
    flex: 3,
    headerName: "Username",
    minWidth: 150,
  },
  {
    field: "user_role_name",
    flex: 3,
    headerName: "Nama Peran",
    minWidth: 150,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateManageUser(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteManageUser(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
