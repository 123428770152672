import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getCoaCategoryColumns = (
  dialogContext,
  handleDeleteCoaCategory,
  handleUpdateCoaCategory
) => [
  {
    field: "id",
    hidden: true,
    headerName: "Id",
    width: 150,
  },
  {
    field: "code",
    headerName: "ID Kategori COA",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "name",
    headerName: "Nama Kategori COA",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon color="info" />}
          label="Edit"
          onClick={() => {
            handleUpdateCoaCategory(params.id);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon color="error" />}
          label="Delete"
          onClick={() => {
            dialogContext.handleOpenDialog(
              () => handleDeleteCoaCategory(params.id),
              "Are you sure want to delete?",
              "Delete Confirmation"
            );
          }}
        />,
    ],
  },
];
