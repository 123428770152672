import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getChartOfAccountColumns = (
  dialogContext,
  handleDeleteChartOfAccount,
  handleUpdateChartOfAccount
) => [
  {
    field: "id",
    headerName: "ID",
    width: 150,
  },
  {
    field: "code",
    headerName: "ID COA",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "name",
    headerName: "Nama COA",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "coa_category_name",
    headerName: "Nama Kategori COA",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "flag",
    headerName: "Flag Akun",
    flex: 3,
    minWidth: 200,
    valueFormatter: (value) => {
      return flagOptions.find((option) => option.id === value)?.name;
    },
  },
  {
    field: "group_account_1",
    headerName: "Group Account 1",
    width: 200,
    valueFormatter: (value) => {
      return autocompleteGroupAccountOptions.find(
        (option) => option.id === value
      )?.name;
    },
  },
  {
    field: "group_account_2",
    headerName: "Group Account 2",
    width: 200,
    valueFormatter: (value) => {
      return autocompleteGroupAccountOptions.find(
        (option) => option.id === value
      )?.name;
    },
  },
  {
    field: "group_account_3",
    headerName: "Group Account 3",
    width: 200,
    valueFormatter: (value) => {
      return autocompleteGroupAccountOptions.find(
        (option) => option.id === value
      )?.name;
    },
  },
  {
    field: "credit_debt_status",
    headerName: "Status D/K",
    flex: 3,
    minWidth: 200,
    valueFormatter: (value) => {
      return creditDebtStatusOptions.find((option) => option.id === value)
        ?.name;
    },
  },
  {
    field: "pos_level",
    headerName: "Level Pos",
    flex: 3,
    minWidth: 200,
    valueFormatter: (value) => {
      return autocompletePosLevelOptions.find((option) => option.id === value)
        ?.name;
    },
  },
  {
    field: "pos_sub_level",
    headerName: "Sub Level Pos",
    flex: 3,
    minWidth: 200,
    valueFormatter: (value) => {
      return autocompletePosSubLevelOptions.find(
        (option) => option.id === value
      )?.name;
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 3,
    minWidth: 200,
    renderCell: ({ row }) => <Checkbox checked={row.status} disabled={true} />,
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateChartOfAccount(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteChartOfAccount(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];

// temporary
const creditDebtStatusOptions = [
  { id: 1, name: "Debit" },
  { id: 2, name: "Kredit" },
];
const flagOptions = [
  { id: 1, name: "Neraca" },
  { id: 2, name: "Laba / Rugi" },
];
const autocompletePosLevelOptions = [
  {
    id: 12,
    name: "AKUMULASI",
  },
  {
    id: 13,
    name: "NONE",
  },
  {
    id: 16,
    name: "PASSIVA",
  },
];
const autocompletePosSubLevelOptions = [
  {
    id: 1,
    pos_level_id: 12,
    name: "NONE",
  },
  {
    id: 2,
    pos_level_id: 13,
    name: "NONE",
  },
  {
    id: 3,
    pos_level_id: 16,
    name: "HUTANG DAGANG",
  },
  {
    id: 4,
    pos_level_id: 16,
    name: "HUTANG PAJAK",
  },
  {
    id: 5,
    pos_level_id: 16,
    name: "EKUITAS",
  },
];
const autocompleteGroupAccountOptions = [
  {
    id: 1,
    name: "Aktiva",
  },
  {
    id: 2,
    name: "Hutang",
  },
  {
    id: 3,
    name: "Modal",
  },
];
