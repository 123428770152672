import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getProductColumns = (
  dialogContext,
  handleDeleteProduct,
  handleUpdateProduct
) => [
  {
    field: "id",
    headerName: "ID",
    width: 200,
  },
  {
    field: "code",
    headerName: "Code",
    width: 200,
  },
  {
    field: "name",
    headerName: "Nama",
    width: 200,
  },
  {
    field: "unit_small_name",
    headerName: "Satuan Kecil",
    width: 200,
    valueGetter: (_, row) => {
      return row?.unit_small?.name ?? "";
    },
  },
  {
    field: "price_small",
    headerName: "Harga Satuan Kecil",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "unit_medium_name",
    headerName: "Satuan Menengah",
    width: 200,
    valueGetter: (_, row) => {
      return row?.unit_medium?.name ?? "";
    },
  },
  {
    field: "price_medium",
    headerName: "Harga Satuan Menengah",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "unit_big_name",
    headerName: "Satuan Besar",
    width: 200,
    valueGetter: (_, row) => {
      return row?.unit_big?.name ?? "";
    },
  },
  {
    field: "price_big",
    headerName: "Harga Satuan Besar",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "purchase_price",
    headerName: "Harga Beli",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "sale_price",
    headerName: "Harga Jual",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "discount_percentage",
    headerName: "Persentase Diskon (%)",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "nominal_discount",
    headerName: "Nominal Diskon",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "actions",
    filterable: false,
    flex: 1,
    headerName: "Aksi",
    minWidth: 200,
    sortable: false,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateProduct(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteProduct(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
