import Forbidden from "../Forbidden";

const AuthElement = ({ children, isShowForbidden, moduleFeatures }) => {
  let isAuth = false;
  const userRoleJson = localStorage.getItem("user_role_json")
    ? JSON.parse(localStorage.getItem("user_role_json"))
    : [];

  for (let i = 0; i < userRoleJson.length; i++) {
    const userRoleValue = userRoleJson[i];
    const moduleValue = moduleFeatures[userRoleValue.module];

    if (!isAuth && moduleValue) {
      isAuth = userRoleValue.features.some(
        (r) => moduleValue.features.indexOf(r) >= 0
      );
    }
  }

  // TODO : uncomment
  isAuth = true;

  return (
    <>
      {isAuth
        ? children
        : isShowForbidden && <Forbidden isShowButton={false} />}
    </>
  );
};

export default AuthElement;
