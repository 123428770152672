import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../contexts/SnackbarContextProvider";
import { companyProfileSchema } from "../../../schemas/companyProfileSchema";
import { getCompanyProfile } from "../../../services/admin/companyProfile/getCompanyProfile";
import { updateCompanyProfile } from "../../../services/admin/companyProfile/updateCompanyProfile";
import { generateSnackbarErrorMessage } from "../../../utils/generateSnackbarErrorMessage";
import styles from "./CompanyProfile.module.scss";

const CompanyProfile = () => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(companyProfileSchema),
  });
  const [companyImage, setCompanyImage] = useState([]);
  const [companyImagePreview, setCompanyImagePreview] = useState([]);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const companyImageRef = useRef([]);
  const snackbarContext = useContext(SnackbarContext);

  const fetchCompanyProfile = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getCompanyProfile();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleChangeCompanyImage = (e) => {
    setCompanyImage(e.target.files);
  };

  const handleRemoveCompanyImage = (index) => {
    // update uploaded image
    let tempCompanyImage = Array.from(companyImage);
    tempCompanyImage.splice(index, 1);

    let list = new DataTransfer();
    tempCompanyImage.forEach((element) => {
      list.items.add(element);
    });

    setCompanyImage(list.files);

    // update ref
    let tempCompanyImageRef = Array.from(companyImageRef.current.files);
    tempCompanyImageRef.splice(index, 1);

    let listRef = new DataTransfer();
    tempCompanyImageRef.forEach((element) => {
      listRef.items.add(element);
    });

    companyImageRef.current.files = listRef.files;
  };

  const handleUpdateCompanyProfile = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      let formData = new FormData();

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          formData.append(key, data[key]);
        }
      }

      // append images
      for (let i = 0; i < companyImage.length; i++) {
        let element = companyImage[i];
        formData.append("photo", element);
      }

      const res = await updateCompanyProfile(formData);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
        setCompanyImage([]);
        companyImageRef.current.files = new DataTransfer().files;
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchCompanyProfile();

    return () => {};
    // eslint-disable-next-line
  }, []);

  // company image preview
  useEffect(() => {
    const fileReaders = [];
    let isCancel = false;
    const tempImagePreview = [];

    if (companyImage.length) {
      Object.values(companyImage).forEach((file) => {
        const fileReader = new FileReader();

        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            tempImagePreview.push(result);
          }
          if (!isCancel) {
            setCompanyImagePreview(tempImagePreview);
          }
        };
        fileReader.readAsDataURL(file);
      });
    } else {
      setCompanyImagePreview([]);
    }

    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [companyImage]);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateCompanyProfile)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="name"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nama"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems="center" direction="row" spacing={3}>
              <span>
                <strong>Unggah Gambar</strong>
              </span>
              <Tooltip placement="right" title="Unggah Gambar">
                <IconButton color="primary" component="label">
                  <input
                    accept="image/*"
                    hidden
                    onChange={(e) => handleChangeCompanyImage(e)}
                    ref={companyImageRef}
                    type="file"
                  />
                  <PhotoCameraIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {companyImagePreview.length <= 0 ? (
            <Grid item xs={12}>
              <em>(Belum ada gambar)</em>
            </Grid>
          ) : (
            companyImagePreview.map((element, index) => {
              return (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <div
                    className={
                      styles["company-profile__image-preview-container"]
                    }
                  >
                    <img
                      alt={`company-profile-preview-${index}`}
                      className={styles["company-profile__image-preview"]}
                      src={element}
                    />
                    <Tooltip title="Delete">
                      <IconButton
                        className={
                          styles["company-profile__delete-image-preview"]
                        }
                        color="error"
                        component="label"
                        onClick={() => handleRemoveCompanyImage(index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              );
            })
          )}
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonUpdate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Simpan
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CompanyProfile;
