import { Button } from "@mui/material";
import Lottie from "lottie-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NotFound.module.scss";
import notFoundJSON from "./notFoundJSON.json";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className={styles["not-found__container"]}>
      <Lottie
        animationData={notFoundJSON}
        className={styles["not-found__animation"]}
        loop={true}
      />
      <Button
        className={styles["not-found__button"]}
        onClick={() => navigate(-1)}
        variant="outlined"
      >
        Back
      </Button>
    </div>
  );
};

export default NotFound;
