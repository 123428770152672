import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getCalculateHppColumns } from "../../../../constants/columns/admin/calculateHpp/getCalculateHppColumns";
import { getCalculateHppDetailColumns } from "../../../../constants/columns/admin/calculateHpp/getCalculateHppDetailColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteCalculateHpp } from "../../../../services/admin/calculateHpp/deleteCalculateHpp";
import { getCalculateHpp } from "../../../../services/admin/calculateHpp/getCalculateHpp";
import { getCalculateHppDetail } from "../../../../services/admin/calculateHpp/getCalculateHppDetail";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const CalculateHppList = () => {
  const [dataGridDetailState, setDataGridDetailState] = useState({
    filterModel: [],
    hID: 0,
    isLoading: false,
    pageIndex: 0,
    rowCount: 0,
    rows: [
      {
        id: "A. Body",
        no: "A. Body",
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: null,
      },
      {
        id: 1,
        no: 1,
        code: "RMFO0061G046",
        name: "Isoc 35",
        weight: 1384,
        composition: 0.346,
        qty: 346600,
        price: 25000,
        purchase1: 26881.72,
        purchase2: 30376.34,
        purchase3: 9301.08,
      },
      {
        id: "Total Body",
        no: "Total Body",
        code: null,
        name: null,
        weight: 4000,
        composition: 1,
        qty: 100,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: 33719,
      },
      {
        id: "yield",
        no: "",
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: 36080,
      },
      {
        id: "C. Kemasan",
        no: "C. Kemasan",
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: null,
      },
      {
        id: 3,
        no: 3,
        code: "PMPO0061G046",
        name: "GUSSET CENTRE SEAL 1 KG",
        weight: null,
        composition: null,
        qty: null,
        price: 1,
        purchase1: 1000.0,
        purchase2: 1075.34,
        purchase3: 1075.34,
      },
      {
        id: "Total Kemasan",
        no: "Total Kemasan",
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: 3083.33,
      },
      {
        id: "D. Lain - lain",
        no: "D. Lain - lain",
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: null,
      },
      {
        id: 4,
        no: 4,
        code: "Listrik + air",
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: 1700,
      },
      {
        id: "Total Lain - lain",
        no: "Total Lain - lain",
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: null,
        purchase2: null,
        purchase3: 3600.0,
      },
      {
        id: "margin",
        no: null,
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: "Margin",
        purchase2: 25.1 + "%",
        purchase3: 57081.0,
      },
      {
        id: "biaya logistik",
        no: null,
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: "Biaya Logistik",
        purchase2: 5.0 + "%",
        purchase3: 60085.0,
      },
      {
        id: "disc distributor",
        no: null,
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: "Disc. Distributor",
        purchase2: 10.0 + "%",
        purchase3: 54076.0,
      },
      {
        id: "gross_margin",
        no: null,
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: "Gross Margin",
        purchase2: 15 + "%",
        purchase3: null,
      },
      {
        id: "per_karton",
        no: null,
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: "Harga Per Karton (12 x 1 kg)",
        purchase2: null,
        purchase3: 721019,
      },
      {
        id: "per_karton_ppn",
        no: null,
        code: null,
        name: null,
        weight: null,
        composition: null,
        qty: null,
        price: null,
        purchase1: "Harga Per Karton (12 x 1 kg) +ppn",
        purchase2: null,
        purchase3: 800331,
      },
    ],
    rowsPerPage: 25,
    sortModel: [],
  });
  const [dataGridState, setDataGridState] = useState({
    filterModel: [],
    isLoading: false,
    pageIndex: 0,
    rowCount: 0,
    rows: [
      {
        id: 1,
        no: 1,
        product_name: "DARK CHOCOLATE COMPOUND BRATACO",
        yield: 7,
        packaging: "12 x 1",
        formula_code: "",
        product_code: "",
        trial_date: "3/21/2024",
        status: "Sudah diterima dan rasa OK",
        revision: 0,
      },
    ],
    rowsPerPage: 25,
    sortModel: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchCalculateHpp = async (
    filterModel,
    pageIndex,
    rowsPerPage,
    sortModel
  ) => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        filterModel: filterModel,
        isLoading: true,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getCalculateHpp({
        filterModel: filterModel,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload.query,
          rowCount: res.payload.rowCount,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchCalculateHppDetail = async (
    filterModel,
    pageIndex,
    rowsPerPage,
    sortModel,
    id
  ) => {
    try {
      setDataGridDetailState((prevState) => ({
        ...prevState,
        filterModel: filterModel,
        isLoading: true,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getCalculateHppDetail({
        filterModel: filterModel,
        pageIndex: pageIndex,
        rowsPerPage: rowsPerPage,
        sortModel: sortModel,
        id: id,
        no: id,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridDetailState((prevState) => ({
          ...prevState,
          rows: res.payload.query,
          rowCount: res.payload.rowCount,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridDetailState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleDeleteCalculateHpp = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteCalculateHpp({
        id: id,
        no: id,
      });

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchCalculateHpp(
          dataGridState.filterModel,
          dataGridState.pageIndex,
          dataGridState.rowsPerPage,
          dataGridState.sortModel
        );
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleGetCalculateHppDetail = (id) => {
    fetchCalculateHppDetail(
      dataGridDetailState.filterModel,
      dataGridDetailState.pageIndex,
      dataGridDetailState.rowsPerPage,
      dataGridDetailState.sortModel,
      id
    );
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleUpdateCalculateHpp = (id) => {
    navigate(`/admin/calculate_hpp/${id}`);
  };

  useEffect(() => {
    fetchCalculateHpp(
      dataGridState.filterModel,
      dataGridState.pageIndex,
      dataGridState.rowsPerPage,
      dataGridState.sortModel
    );

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog}
        open={isOpenDialog}
      >
        <DialogTitle>Detail Hpp</DialogTitle>
        <Divider />
        <DialogContent>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            columns={getCalculateHppDetailColumns()}
            rows={dataGridDetailState.rows}
            sx={{ height: "50vh", minHeight: 400, width: "100%" }}
          />
        </DialogContent>
      </Dialog>
      <DataGrid
        columns={getCalculateHppColumns(
          dialogContext,
          handleDeleteCalculateHpp,
          handleGetCalculateHppDetail,
          handleUpdateCalculateHpp
        )}
        rows={dataGridState.rows}
        sx={{ height: "70vh", minHeight: 600, width: "100%" }}
      />
    </>
  );
};

export default CalculateHppList;
