import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import EmployeeCreate from "./EmployeeCreate";
import EmployeeList from "./EmployeeList";

const Employee = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label="Tambah Karyawan" value="0" />
            <Tab label="Daftar Karyawan" value="1" />
          </TabList>
        </Box>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.EMPLOYEE.key]: {
              module: modules.EMPLOYEE.key,
              features: [features.CREATE_DATA],
            },
          }}
        >
          <TabPanel value="0">
            <EmployeeCreate />
          </TabPanel>
        </AuthElement>
        <AuthElement
          isShowForbidden
          moduleFeatures={{
            [modules.EMPLOYEE.key]: {
              module: modules.EMPLOYEE.key,
              features: [features.READ_DATA],
            },
          }}
        >
          <TabPanel value="1">
            <EmployeeList />
          </TabPanel>
        </AuthElement>
      </TabContext>
    </Paper>
  );
};

export default Employee;
