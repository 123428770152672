export const getFpdDetailColumns = () => [
  {
    field: "id",
    headerName: "ID",
  },
  {
    field: "coa_code",
    headerName: "ID COA",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "coa_name",
    flex: 3,
    headerName: "Nama COA",
    minWidth: 200,
  },
  {
    field: "description",
    headerName: "Deskripsi",
    flex: 3,
    minWidth: 200,
  },
  {
    field: "amount",
    headerName: "Nilai Transaksi",
    flex: 3,
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
];
