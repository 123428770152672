import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { LoadingButton } from "@mui/lab";
import {
  Badge,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useContext, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { calculateHppSchema } from "../../../../schemas/calculateHppSchema";
import { createCalculateHpp } from "../../../../services/admin/calculateHpp/createCalculateHpp";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import CalculateHppBodyDialog from "../CalculateHppBodyDialog";
import CalculateHppPackagesDialog from "../CalculateHppPackagesDialog";
import CalculateHppText from "../CalculateHppText";

const CalculateHppCreate = () => {
  const {
    control,
    getValues,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      body: [],
      body_total_weight: 0,
      body_total_composition: 0,
      body_total_qty_in_percent: 0,
      body_total_ppb7_no_ppn: 0,
      body_total_yield: 0,
      distributor_disc: 0,
      distributor_disc_total: 0,
      employee_rate: 0,
      ex_work: 0,
      foh_rate: 0,
      formula_code: "",
      gross_margin: 0,
      logistic_price: 0,
      logistic_price_total: 0,
      margin: 0,
      margin_total: 0,
      others_total: 0,
      packages: [],
      packages_total_ppb7_no_ppn: 0,
      packaging: 0,
      price_per_carton: 0,
      product_code: "",
      price_per_carton_ppn: 0,
      product_name: "",
      resources_rate: 0,
      revision: 0,
      status: "",
      trial_date: "",
      yield: 0,
    },
    shouldUnregister: false,
    resolver: yupResolver(calculateHppSchema),
  });
  const {
    fields: fieldsBody,
    remove: removeBody,
    append: appendBody,
  } = useFieldArray({
    control,
    name: "body",
  });
  const {
    fields: fieldsPackages,
    remove: removePackages,
    append: appendPackages,
  } = useFieldArray({
    control,
    name: "packages",
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const calculateHppBodyDialog = useRef();
  const calculateHppPackagesDialog = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const calculateYield = () => {
    const values = getValues();
    const yieldd = 1 + (values.yield ?? 0) / 100;

    setValue(
      "body_total_yield",
      parseInt((values.body_total_ppb7_no_ppn * yieldd).toFixed())
    );

    calculateExWork();
  };

  const calculateOthers = () => {
    const values = getValues();

    setValue(
      "others_total",
      (values?.resources_rate ?? 0) +
        (values?.employee_rate ?? 0) +
        (values?.foh_rate ?? 0)
    );

    calculateExWork();
  };

  const calculateExWork = () => {
    const values = getValues();

    setValue(
      "ex_work",
      (parseInt(values?.body_total_yield) ?? 0) +
        (parseInt(values?.packages_total_ppb7_no_ppn) ?? 0) +
        (parseInt(values?.others_total) ?? 0)
    );

    calculateFinal();
  };

  const calculateFinal = () => {
    const values = getValues();

    const marginTotal = values.ex_work / (1 - values.margin / 100);
    setValue("margin_total", marginTotal);

    const logisticPriceTotal = marginTotal / (1 - values.logistic_price / 100);
    setValue("logistic_price_total", logisticPriceTotal);

    const discDistTotal =
      logisticPriceTotal / (1 - values.distributor_disc / 100);
    setValue("distributor_disc_total", discDistTotal);

    const grossMargin = values.margin - values.distributor_disc;
    setValue("gross_margin", parseInt(grossMargin.toFixed()));

    const pricePerCarton = logisticPriceTotal * values.packaging;
    setValue("price_per_carton", pricePerCarton);

    const pricePerCartonPpn = pricePerCarton * 1.11;
    setValue("price_per_carton_ppn", pricePerCartonPpn);
  };

  const handleCreateCalculateHpp = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createCalculateHpp(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleCreateCalculateHpp)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="product_name"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nama Produk"
                  onChange={onChange}
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={"yield"}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Rendeman"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateYield();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="packaging"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">x 1</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Kemasan"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateFinal();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="formula_code"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Kode Formula"
                  onChange={onChange}
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="product_code"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Kode Produk"
                  onChange={onChange}
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="trial_date1"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Trial"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                      variant: "standard",
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="trial_date2"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Trial"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                      variant: "standard",
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="trial_date3"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Trial"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                      variant: "standard",
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="trial_date4"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Trial"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                      variant: "standard",
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="trial_date5"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Trial"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                      variant: "standard",
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="status"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Status"
                  onChange={onChange}
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={"revision"}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  inputRef={ref}
                  label="Revisi Ke-"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
              <Badge
                badgeContent={fieldsBody?.length || null}
                color={errors.body ? "error" : "success"}
              >
                <Button
                  endIcon={<ChecklistIcon />}
                  loadingPosition="end"
                  onClick={() =>
                    calculateHppBodyDialog.current.handleOpenDialog()
                  }
                  variant="contained"
                >
                  Detail Body
                </Button>
              </Badge>
            </Stack>
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`body_total_weight`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Berat"}
                  description={`${value ?? 0} Kg`}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`body_total_composition`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Komposisi"}
                  description={value ?? 0}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`body_total_qty_in_percent`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Qty"}
                  description={`${value ?? 0} %`}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`body_total_ppb7_no_ppn`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Body"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`body_total_yield`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Rendeman"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
              <Badge
                badgeContent={fieldsPackages?.length || null}
                color={errors.packages ? "error" : "success"}
              >
                <Button
                  endIcon={<ChecklistIcon />}
                  loadingPosition="end"
                  onClick={() =>
                    calculateHppPackagesDialog.current.handleOpenDialog()
                  }
                  variant="contained"
                >
                  Detail Kemasan
                </Button>
              </Badge>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`packages_total_ppb7_no_ppn`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Kemasan"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="resources_rate"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Listrik + Air"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateOthers();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="employee_rate"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Karyawan"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateOthers();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="foh_rate"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="FOH"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateOthers();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`others_total`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Total Lain-lain"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`ex_work`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Ex work in Kg (sebelum margin)"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="margin"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Margin (%)"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateFinal();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`margin_total`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Margin"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>

          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="logistic_price"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Biaya Logistik (%)"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateFinal();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`logistic_price_total`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Biaya Logistik"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="distributor_disc"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Disc. Distributor (%)"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                    calculateFinal();
                  }}
                  thousandSeparator=","
                  value={value}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`distributor_disc_total`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Disc. Distributor"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>

          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`gross_margin`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Gross Margin"}
                  description={`${value} %`}
                />
              )}
            />
          </Grid>

          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`price_per_carton`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Harga per Karton"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>

          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name={`price_per_carton_ppn`}
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <CalculateHppText
                  title={"Harga per Karton + PPN"}
                  description={new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    style: "currency",
                  }).format(value ?? 0)}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<AddIcon />}
                loading={isLoadingButtonState.buttonCreate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Add
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>

      <CalculateHppBodyDialog
        append={appendBody}
        calculateExWork={calculateExWork}
        control={control}
        fields={fieldsBody}
        getValues={getValues}
        ref={calculateHppBodyDialog}
        remove={removeBody}
        setValue={setValue}
        title={"Body"}
      />
      <CalculateHppPackagesDialog
        append={appendPackages}
        calculateExWork={calculateExWork}
        control={control}
        fields={fieldsPackages}
        getValues={getValues}
        ref={calculateHppPackagesDialog}
        remove={removePackages}
        setValue={setValue}
        title={"Kemasan"}
      />
    </>
  );
};

export default CalculateHppCreate;
