import * as yup from "yup";

export const productSchema = yup.object().shape({
  code: yup.string().required("Mohon mengisi kode barang."),
  discount_percentage: yup.number().notRequired(),
  name: yup.string().required("Mohon mengisi nama barang."),
  nominal_discount: yup.number().notRequired(),
  price_big: yup.number().required("Mohon mengisi harga satuan besar."),
  price_medium: yup.number().required("Mohon mengisi harga satuan menengah."),
  price_small: yup.number().required("Mohon mengisi harga satuan kecil."),
  purchase_price: yup.number().required("Mohon mengisi harga beli."),
  sale_price: yup.number().required("Mohon mengisi harga jual."),
  unit_big: yup.object().required("Mohon mengisi satuan besar."),
  unit_medium: yup.object().required("Mohon mengisi satuan menengah."),
  unit_small: yup.object().required("Mohon mengisi satuan kecil."),
});
