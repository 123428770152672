import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { productSchema } from "../../../../schemas/productSchema";
import { createProduct } from "../../../../services/admin/product/createProduct";
import { getUnit } from "../../../../services/admin/unit/getUnit";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ProductCreate = () => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      code: "",
      discount_percentage: 0,
      name: "",
      nominal_discount: 0,
      price_big: 0,
      price_medium: 0,
      price_small: 0,
      purchase_price: 0,
      sale_price: 0,
      unit_big: null,
      unit_medium: null,
      unit_small: null,
    },
    resolver: yupResolver(productSchema),
  });
  const [autocompleteUnitBigOptions, setAutocompleteUnitBigOptions] = useState(
    []
  );
  const [autocompleteUnitMediumOptions, setAutocompleteUnitMediumOptions] =
    useState([]);
  const [autocompleteUnitSmallOptions, setAutocompleteUnitSmallOptions] =
    useState([]);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  const [isLoadingAutocompleteUnitBig, setIsLoadingAutocompleteUnitBig] =
    useState(false);
  const [isLoadingAutocompleteUnitMedium, setIsLoadingAutocompleteUnitMedium] =
    useState(false);
  const [isLoadingAutocompleteUnitSmall, setIsLoadingAutocompleteUnitSmall] =
    useState(false);
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompleteUnitBig, setIsOpenAutocompleteUnitBig] =
    useState(false);
  const [isOpenAutocompleteUnitMedium, setIsOpenAutocompleteUnitMedium] =
    useState(false);
  const [isOpenAutocompleteUnitSmall, setIsOpenAutocompleteUnitSmall] =
    useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateProduct = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createProduct(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete unit big
  useEffect(() => {
    setAutocompleteUnitBigOptions([]);

    if (!isOpenAutocompleteUnitBig) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUnitBig(true);

        const res = await getUnit();

        if (res.status === 200) {
          setAutocompleteUnitBigOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUnitBig(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUnitBig]);

  // autocomplete unit medium
  useEffect(() => {
    setAutocompleteUnitMediumOptions([]);

    if (!isOpenAutocompleteUnitMedium) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUnitMedium(true);

        const res = await getUnit();

        if (res.status === 200) {
          setAutocompleteUnitMediumOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUnitMedium(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUnitMedium]);

  // autocomplete unit small
  useEffect(() => {
    setAutocompleteUnitSmallOptions([]);

    if (!isOpenAutocompleteUnitSmall) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUnitSmall(true);

        const res = await getUnit();

        if (res.status === 200) {
          setAutocompleteUnitSmallOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUnitSmall(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUnitSmall]);

  return (
    <form onSubmit={handleSubmit(handleCreateProduct)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="code"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kode Barang"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Barang"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="unit_small"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUnitSmall}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUnitSmall(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUnitSmall(true);
                }}
                open={isOpenAutocompleteUnitSmall}
                options={autocompleteUnitSmallOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUnitSmall ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Satuan Kecil"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="price_small"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Satuan Kecil"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="unit_medium"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUnitMedium}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUnitMedium(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUnitMedium(true);
                }}
                open={isOpenAutocompleteUnitMedium}
                options={autocompleteUnitMediumOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUnitMedium ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Satuan Menengah"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="price_medium"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Satuan Menengah"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="unit_big"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUnitBig}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUnitBig(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUnitBig(true);
                }}
                open={isOpenAutocompleteUnitBig}
                options={autocompleteUnitBigOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUnitBig ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Satuan Besar"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="price_big"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Satuan Besar"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="purchase_price"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Beli"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="sale_price"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Jual"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="discount_percentage"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputRef={ref}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                label="Persentase Diskon"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="nominal_discount"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Nominal Diskon"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductCreate;
