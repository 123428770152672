import * as yup from "yup";

export const calculateBudgetSchema = yup.object().shape({
  budget_total: yup.number(),
  date: yup.date().required("Mohon mengisi Tanggal."),
  details: yup
    .array()
    .of(
      yup.object().shape({
        amount: yup.number().required("Mohon mengisi Jumlah."),
        description: yup.string().required("Mohon mengisi Keterangan."),
        title: yup.string().required("Mohon mengisi Detail."),
      })
    )
    .min(1, "Data Budget minimal 1"),
  division: yup.string().required("Mohon mengisi Divisi."),
  employee: yup.string().required("Mohon mengisi Karyawan."),
});
