import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import { Grid, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { currencySchema } from "../../../../schemas/currencySchema";
import { createEmployee } from "../../../../services/employee/createEmployee";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const CurrencyCreate = () => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      exchange_rate: "",
      name: "",
    },
    resolver: yupResolver(currencySchema),
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const handleCreateCurrency = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await createEmployee(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleCreateCurrency)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name={"exchange_rate"}
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Kurs (Rp)"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default CurrencyCreate;
