import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useState } from "react";
import AuthElement from "../../../components/AuthElement";
import { features } from "../../../constants/features";
import { modules } from "../../../constants/modules";
import CalculateHppCreate from "./CalculateHppCreate";
import CalculateHppList from "./CalculateHppList";

const CalculateHpp = () => {
  const [tabValue, setTabValue] = useState("0");

  const handleChangeTabValue = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            allowScrollButtonsMobile
            onChange={handleChangeTabValue}
            scrollButtons
            variant="scrollable"
          >
            <Tab label="Tambah Perhitungan HPP" value="0" />
            <Tab label="Daftar Perhitungan HPP" value="1" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.CALCULATE_HPP.key]: {
                module: modules.CALCULATE_HPP.key,
                features: [features.CREATE_DATA],
              },
            }}
          >
            <CalculateHppCreate />
          </AuthElement>
        </TabPanel>
        <TabPanel value="1">
          <AuthElement
            isShowForbidden
            moduleFeatures={{
              [modules.CALCULATE_HPP.key]: {
                module: modules.CALCULATE_HPP.key,
                features: [features.READ_DATA],
              },
            }}
          >
            <CalculateHppList />
          </AuthElement>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default CalculateHpp;
