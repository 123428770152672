import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Badge,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { purchaseOrderSchema } from "../../../../schemas/purchaseOrderSchema";
import { getPartner } from "../../../../services/admin/partner/getPartner";
import { createPurchaseOrder } from "../../../../services/admin/purchaseOrder/createPurchaseOrder";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import PurchaseOrderDialog from "../PurchaseOrderDialog";

const PurchaseOrderCreate = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
      date: new Date(),
      discount_percentage: 0,
      down_payment: 0,
      dpp: 0,
      invoice_discount: 0,
      partner: null,
      pph22: 0,
      ppn: 0,
      purchase_order_detail: [],
      sale_price: 0,
    },
    resolver: yupResolver(purchaseOrderSchema),
  });
  const { append, fields, remove } = useFieldArray({
    control,
    name: "purchase_order_detail",
  });
  const [autocompletePartnerOptions, setAutocompletePartnerOptions] = useState(
    []
  );
  const [isLoadingAutocompletePartner, setIsLoadingAutocompletePartner] =
    useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompletePartner, setIsOpenAutocompletePartner] =
    useState(false);
  const purchaseOrderDialogRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const handleCreatePurchaseOrder = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.date = format(data.date, "yyyy-MM-dd HH:mm:ss");

      const res = await createPurchaseOrder(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete partner
  useEffect(() => {
    setAutocompletePartnerOptions([]);

    if (!isOpenAutocompletePartner) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompletePartner(true);

        const res = await getPartner();

        if (res.status === 200) {
          setAutocompletePartnerOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompletePartner(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompletePartner]);

  return (
    <>
      <form onSubmit={handleSubmit(handleCreatePurchaseOrder)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="code"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  // disabled
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nomor PO"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="date"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal PO"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="partner"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompletePartner}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompletePartner(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompletePartner(true);
                  }}
                  open={isOpenAutocompletePartner}
                  options={autocompletePartnerOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingAutocompletePartner ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label="Pelanggan"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="discount_percentage"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 100;
                  }}
                  label="Persentase Diskon"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="invoice_discount"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Diskon Invoice"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="dpp"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="DPP"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="sale_price"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Harga Jual"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="down_payment"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="Uang Muka"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="pph22"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  label="PPH22"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="ppn"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 100;
                  }}
                  label="PPN"
                  onValueChange={(values) => {
                    onChange(values.floatValue);
                  }}
                  thousandSeparator=","
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={3}>
              <Badge
                badgeContent={fields.length || null}
                color={errors.purchase_order_detail ? "error" : "success"}
              >
                <Button
                  endIcon={<ChecklistIcon />}
                  loadingPosition="end"
                  onClick={() =>
                    purchaseOrderDialogRef.current.handleOpenDialog()
                  }
                  variant="contained"
                >
                  Detail Barang
                </Button>
              </Badge>
              <LoadingButton
                endIcon={<AddIcon />}
                loading={isLoadingButtonState.buttonCreate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Add
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <PurchaseOrderDialog
        append={append}
        control={control}
        fields={fields}
        ref={purchaseOrderDialogRef}
        remove={remove}
        title={"Barang"}
      />
    </>
  );
};

export default PurchaseOrderCreate;
